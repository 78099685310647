body {
    margin: 0;
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

/*******************************************
      Navbar
      ********************************************/
.nv-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    background-color: #2779BD;
}

.nv-li {
    float: left;
}

.nv-li a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 8px 16px;
    text-decoration: none;
}

.nv-li.super-user a:hover {
    background-color: #CC1F1A;
}

.nv-brand-font {
    font-family: 'Exo', sans-serif;
    font-weight: bold;
    font-style: italic;
    font-size: 20px;

}

.nv-li-logo a {
    padding: 4px 16px;
}

/***********************************
Sections
***********************************/
h1 {
    font-size: 60px;
    margin: 0px;
}

h3 {
    font-size: 30px;
    margin: 0px;
    font-weight: 300;
}

@media (max-width: 460px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-460.jpeg");
    }
}

@media (min-width: 460px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-720.jpeg");
    }
}

@media (min-width: 720px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-980.jpeg");
    }
}

@media (min-width: 980px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-1240.jpeg");
    }
}

@media (min-width: 1240px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-1500.jpeg");
    }
}

@media (min-width: 1500px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains-1760.jpeg");
    }
}

@media (min-width: 1760px) {
    .hero-image-file {
        background-image: url("../public/images/airplane-mountains.jpeg");
    }
}

:root {
    --side-panel-width: 450px;
    --side-panel-transition-duration: 500ms;
}

.hero-image {
    background-size: cover;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 10%;

    color: white;
}

@media (min-width: 720px) {
    .hero-text {
        left: 20%;
        right: 20%;
    }
}

@media (min-width: 150px) {
    .email-image-file {
        background-image: url("../public/images/mail-sent-success.svg");
    }
}

.email-image {
    background-size: cover;
    height: 150px;
    width: 150px;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
}

.semi-transparent-overlay {
    background-color: hsla(0, 0%, 0%, .25);
    /*height: 600px;*/
}

.future-font {
    font-family: 'Exo', sans-serif;
    font-weight: bold;
    font-style: italic;
}

.btn {
    border: 2px solid black;
    border-radius: 5px;
    background-color: hsla(0, 0%, 0%, 0);
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
}

.default {
    border-color: rgba(255, 255, 255, 0.6);
    color: white;
}

.default:hover {
    border-color: white;
}

.default_bottom {
    margin-right: 10px;
    text-decoration: none;
}

.primary {
    border-color: #2779BD;
    background-color: hsla(207, 66%, 45%, 0.55);
    color: white;
}

.primary:hover {
    border-color: white;
}

.title-separator {
    width: 370px;
    max-width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, .14);
    display: block;
    margin: 30px auto 0 auto;
    background-color: #2779BD;
}

.answer {
    margin-top: 30px;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 50px;
    font-size: 24px;
    letter-spacing: 0.02em;
    text-transform: none;
    line-height: 40px;
}

.answer_right {
    font-size: 20px;
    padding-bottom: 10px;
    margin-top: 5px;
}

.what-can-isam-do-for-you {
    background-color: #cbd5e0;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    width: 300px;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    flex-basis: 29%;
}

@media (min-width: 1657px) {
    .card {
        flex-basis: 20%;
    }
}

.card-icon {
    font-size: 40px;
    color: #2779BD;
    margin-bottom: 10px;
}

.card-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.card-body {
    font-size: 16px;
}

.section-title {
    margin-top: 30px;
    padding-top: 30px;
    font-size: 30px;
}

.center-content {
    text-align: center;
}

.splash {
    color: white;
}

.formStyle {
    width: 80%;
    margin: 10px auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.formControl {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.formBtn {
    float: right;
    position: relative;
}

.side-panel-handle {
    background-color: #ababab;
    writing-mode: vertical-lr;
    border-radius: 0px 5px 5px 0px;
    width: 20px;
    height: 80px;
    position: absolute;
    top: 45vh;
    left: 0px;
    padding-top: 10px;
    padding-right: 5px;
    z-index: 100;
    transition-duration: var(--side-panel-transition-duration);
    transition-property: left;
}

.side-panel-handle.side-panel-open {
    left: var(--side-panel-width);
    transition-duration: var(--side-panel-transition-duration);
    transition-property: left;
}

.side-panel-handle:hover {
    background-color: rgb(146, 146, 146);
}

.side-panel-content {
    width: var(--side-panel-width);
    margin-left: calc(-1.10*var(--side-panel-width));
    position: absolute;
    z-index: 99;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ababab;
    border-top: 1px solid #ababab;
    
    border-radius: 0px 10px 0px 0px;
    transition-duration: var(--side-panel-transition-duration);
    transition-property: margin-left;
}

.side-panel-content.side-panel-open {
    margin-left: 0px;
    transition-duration: var(--side-panel-transition-duration);
    transition-property: margin-left;
}

.table th {
    background: #fff;
    border: 1px solid #ddd;
    font-weight: bold;
}


.table td {
    border-top: 1px solid #ddd;
}

.table tbody td {
    border: 1px solid #ddd;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:nth-child(n) td {
    background: #eff0f0;
}

.table tbody tr:nth-child(2n) td {
    background: #fff;
}
/*
button:focus, input:focus, a:focus, 
ul.MuiTreeView-root:focus, li.MuiTreeItem-root:focus, button.MuiTab-labelIcon:focus {
    border: #cbd5e0 solid 1px;
}
*/