@import "~@joint/plus/joint-plus.css";


.canvas {
    width: 100%;
    height: 100%;
}

.selected rect {
    stroke: red;
}

.selected ellipse {
    stroke: red;
}

.isam-grow-container > .isam-grow {
    -webkit-backface-visibility: hidden;
    transition: transform 0.2s;
}

.isam-grow-container:hover > .isam-grow {
    -webkit-backface-visibility: hidden;
    transform: scale(1.4);
    transition: transform 0.2s;
}

.dna-failure rect {
    fill: red !important;
    fill-opacity: 0.5 !important;
}

.dna-failure ellipse {
    fill: red !important;
    fill-opacity: 0.5 !important;
}
