.action-sash-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #eee;
    width: 7px;
}

.action-sash-wrap .action {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    background-color: rgb(39,121,189);
   /* color: #100f0f; */
    width: 100%;
    height: 30px;
}


.action-sash-wrap .action:hover {
    cursor: pointer;
}

.hidden-sash {
    display: none;
}