/* Please include any new CSS file created under stylesheets folder in this file. */

/* CSS Variables */

:root {
  --white: #ffffff;
  --light-blue: #0076c0;
  --dark-blue: #002664;
  --lighter-grey: #e9eaed;
  --slate: #333333;
  --black: #000;
  --charcoal: #353535;
  --primary-blue: #003e7e;
  --secondary-grey: #6e7476;
  --light-grey: #cccccc;
  --navy-blue: #0071bc;
  --background-grey: #d0d0d0;
  --whaleblue: #005690;
  --ocean-blue: #0d76bd;
  --hover-blue: #0e76bd;
  --grey: #e6e6e6;
  --link-active: var(--whaleblue);
  --purple: #7a0098;
  --link-visited: var(--purple);
  --darker-grey: #797d7f;
  --asbestos: #aab3b6;
  --cyan: #d7dada;

  /* Bottom Bar blue gradient background colors */
  --bottom-bar-dark-blue: #002663;
  --bottom-bar-darker-blue: #000933;

  /* Footer gradient background colors */
  --footer-lighter-grey-background: #efefef;
  --footer-light-grey-background: var(--grey);

  --dialog-background: var(--ocean-blue);
  --tab-background: var(--grey);
  --tab-hover-background: var(--light-blue);

  --table-grey: #f4f6f9;

  /* Alert Colors*/
  --green: #22763b;
  --applegreen: #e7f4e4;
  --red: #c32026;
  --tutu: #f9dede;
  --yellow: #fdb81e;
  --varden: #fff1d2;
  --sky-blue: #02bfe7;
  --light-cyan: #e1f3f8;

  /* External Template color vars */
  --ext-microhead-background: #15396c;
  --external-dark-blue: #003e7e;
  --external-nav-active-background: #111111;
  --external-gold: #886a05;
  --external-list-border: #dddddd;

  --ext-header-top-border: #b28706;

  --ext-footer-top-border: #937206;
  --ext-footer-bottom-border: #00264d;
  --ext-dot-footer-gradient: #f3f3f3;
}

:root {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

:root {
  margin: 0 auto;
}

/* some generic CSS rules and CSS for back to home link */

html {
  overflow-x: hidden;
}

body {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #333333;
  color: var(--slate);
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body p {
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 1.875rem;
}

body legend {
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0 6px 0 0;
  border: 0;
}

body {
  & h1,
  & h2,
  & .devkit-h2,
  & h3,
  & .devkit-h3,
  & h4,
  & .devkit-h4,
  & h5,
  & .devkit-h5,
  & h6 & .devkit-h6 {
    margin: 12px 0 12px 0;
  }
}

body h1 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

body h2,
body .devkit-h2 {
  font-size: 1.25rem;
}

body h3,
body .devkit-h3 {
  font-size: 1.125rem;
}

body .devkit-h2,
body .devkit-h4,
body .devkit-h3 {
  font-weight: 600;
}

body {
  & h4,
  .devkit-h4 {
    font-size: 1rem;
  }

  & h5,
  .devkit-h5 {
    font-size: 0.875rem;
  }

  & h6,
  .devkit-h6 {
    font-size: 0.8125rem;
  }
}

body a:link {
  color: #005690;
  color: var(--link-active);
  text-decoration: none;
}

body a:visited {
  color: #7a0098;
  color: var(--link-visited);
  text-decoration: none;
}

body a:hover {
  color: #000;
  color: var(--black);
  text-decoration: underline;
  cursor: pointer;
}

body a:active {
  color: #005690;
  color: var(--link-active);
  text-decoration: none;
}

body .loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

body .loader .fa-spinner,
body .loader .loading-text {
  display: inline;
}

body .loader .fa-spinner {
  font-size: 1.75rem;
}

body .loader .loading-text {
  padding-left: 6px;
  font-size: 1.125rem;
  font-weight: 600;
}

body #devkit-code-sample {
  cursor: text;
}

body .devkit-code-button {
  display: block;
  width: 150px;
  margin-top: 30px;
  border: 0;
}

body pre[class*="language-"] {
  display: none;
  position: relative;
  overflow-x: auto;
  max-width: 90%;
  margin-top: 18px;
  padding-top: 42px;
  background: #333333;
  background: var(--slate);
  color: #ffffff;
  color: var(--white);
  text-shadow: none;
}

body pre[class*="language-"] .copy-button {
  content: "Copy";
  cursor: pointer;
  border: 0;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 6px 10px;
  color: #333333;
  color: var(--slate);
  background: #ffffff;
  background: var(--white);
  position: absolute;
  top: 10px;
  left: 10px;
}

body pre[class*="language-"] .fa-times {
  cursor: pointer;
  border: 0;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 3px;
  color: #ffffff;
  color: var(--white);
  position: absolute;
  top: 10px;
  right: 10px;
}

body pre[class*="language-"] code {
  overflow: scroll;
  word-wrap: break-word;
  text-shadow: none;
  color: #ffffff;
  color: var(--white);
  margin-top: 24px;
}

body .devkit-help-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 35;
  background: #797d7f;
  background: var(--darker-grey);
  opacity: 0.65;
}

body ul.devkit-info-list,
body ul.devkit-left-info-list,
body ul.devkit-right-info-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  line-height: 1.875rem;
}

body ul.devkit-info-list li a,
body ul.devkit-info-list li a:link,
body ul.devkit-info-list li a:visited,
body ul.devkit-left-info-list li a,
body ul.devkit-left-info-list li a:link,
body ul.devkit-left-info-list li a:visited,
body ul.devkit-right-info-list li a,
body ul.devkit-right-info-list li a:link,
body ul.devkit-right-info-list li a:visited {
  color: #005690;
  color: var(--link-active);
}

body ul.devkit-info-list li a:hover,
body ul.devkit-left-info-list li a:hover,
body ul.devkit-right-info-list li a:hover {
  color: #000;
  color: var(--black);
  text-decoration: underline;
}

body ul.devkit-info-list li .fa-arrow-down,
body ul.devkit-left-info-list li .fa-arrow-down,
body ul.devkit-right-info-list li .fa-arrow-down {
  font-size: 0.875rem;
  color: #797d7f;
  color: var(--darker-grey);
}

body .devkit-info-list-wrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 600px;
}

body .devkit-text-bold {
  font-weight: 600;
}

body .devkit-blue-text {
  color: #003e7e;
  color: var(--primary-blue);
}

body .devkit-code-wrapper {
  display: flex;
  flex-flow: column;
  align-content: center;
}

body .devkit-code {
  font-size: 0.875rem;
}

body .devkit-code h4,
body .devkit-code .devkit-h4 {
  margin-top: 0;
  margin-bottom: 12px;
}

body .devkit-code p {
  line-height: 1.125rem;
  margin-bottom: 0;
}

body .devkit-code p:last-of-type {
  margin-bottom: 18px;
}

body .devkit-code .devkit-code-attr {
  font-weight: 600;
}

body .devkit-notes-wrapper {
  margin-bottom: 24px;
}

@media screen and (min-width: 960px) {
  body legend {
    font-size: 1.25rem;
  }

  body h1,
  body h2,
  body .devkit-h2,
  body h3,
  body .devkit-h3,
  body h4,
  body .devkit-h4 {
    margin: 12px 0;
  }
  body {
    & h5,
    .devkit-h5,
    & h6,
    .devkit-h6 {
      margin: 6px 0;
    }
  }

  body h1 {
    font-size: 1.875rem;
  }

  body h2,
  body .devkit-h2 {
    font-size: 1.5rem;
  }

  body h3,
  body .devkit-h3 {
    font-size: 1.1875rem;
  }

  body h4,
  body .devkit-h4 {
    font-size: 1rem;
  }

  body h5,
  body .devkit-h5 {
    font-size: 0.875rem;
  }

  body h6,
  body .devkit-h6 {
    font-size: 0.8125rem;
  }

  body .devkit-info-list-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 600px;
  }

  body .devkit-code-wrapper {
    display: flex;
    justify-content: flex-start;
    margin: 24px auto;
  }

  body .devkit-code-wrapper .devkit-code:first-of-type {
    padding-right: 48px;
    border-right: 2px solid #797d7f;
    border-right: 2px solid var(--darker-grey);
  }

  body .devkit-code-wrapper .devkit-code:last-of-type {
    padding-left: 48px;
  }
}

/*
Not standard and does not work  on all browsers: https: //developer.mozilla.org/en-US/docs/Web/CSS/zoom
.zoomout {
  zoom: 85%;
}
*/

/* CSS for checkboxes and radio buttons */

/* This is to hide default checkbox and radio button */

[type="checkbox"],
[type="radio"] {
  opacity: 0;
  position: absolute;
}

[type="checkbox"] + label,
[type="radio"] + label {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 6px;
}

[type="checkbox"] + label::before,
[type="radio"] + label::before {
  background: #ffffff;
  background: var(--white);
  content: "\a0";
  display: inline-block;
}

[type="checkbox"] + label::before {
  width: 20px;
  height: 20px;
  line-height: 2rem;
  margin: 0 10px 10px 1px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #6e7476;
  box-shadow: 0 0 0 1px var(--secondary-grey);
  vertical-align: top;
}

[type="radio"] + label::before {
  width: 16px;
  height: 16px;
  margin: 0 12px 12px 3px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #6e7476;
  box-shadow: 0 0 0 2px var(--white), 0 0 0 3px var(--secondary-grey);
  line-height: 1.375rem;
  vertical-align: text-top;
}

[type="checkbox"]:checked + label::before,
[type="radio"]:checked + label::before {
  background-color: #0071bc;
  background-color: var(--navy-blue);
  box-shadow: 0 0 0 1px #0071bc;
  box-shadow: 0 0 0 1px var(--navy-blue);
}

[type="radio"]:checked + label::before {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0071bc;
  box-shadow: 0 0 0 2px var(--white), 0 0 0 4px var(--navy-blue);
}

[type="checkbox"]:checked + label::before,
[type="checkbox"]:checked:disabled + label::before {
  background-image: url("../images/correct8.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
}

[type="radio"]:focus + label::before {
  outline: 2px dotted #aab3b6;
  outline: 2px dotted var(--asbestos);
  outline-offset: 6px;
}

[type="checkbox"]:disabled + label {
  color: #cccccc;
  color: var(--light-grey);
}

[type="checkbox"]:focus + label::before {
  outline: 2px dotted #aab3b6;
  outline: 2px dotted var(--asbestos);
  outline-offset: 3px;
}

[type="checkbox"]:disabled + label::before,
[type="radio"]:disabled + label::before {
  background: #d7dada;
  background: var(--cyan);
  box-shadow: 0 0 0 1px #aab3b6;
  box-shadow: 0 0 0 1px var(--asbestos);
  cursor: not-allowed;
}

/* GLOBAL COMPONENTS CSS */

/* Common */

.devkit-al-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.devkit-al-wrapper .devkit-ordered-list-wrapper {
  width: 300px;
}

.devkit-al-wrapper .devkit-unordered-list-wrapper {
  width: 350px;
}

@media screen and (min-width: 1280px) {
  .devkit-al-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.devkit-color-palette-wrapper {
  & h2:first-of-type & .devkit-h2:first-of-type {
    margin-top: 24px;
  }
}

.devkit-color-palette-wrapper .devkit-warning-message-wrapper {
  margin: 18px auto;
}

.devkit-color-palette-wrapper .devkit-cp-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  height: auto;
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-color-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 12px 12px 12px;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-light-blue,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-primary-blue,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-lighter-grey,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-slate,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-green,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-yellow,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-red,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-sky-blue,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-apple-green,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-varden,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-tutu,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-light-cyan {
  width: 150px;
  height: 150px;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-name,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-hex-text,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-rgb-text {
  font-weight: 600;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-hex {
  margin-top: 12px;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-name {
  font-size: 1.125rem;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-hex,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-rgb {
  padding-left: 18px;
}

.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-hex,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-color-rgb,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-hex-text,
.devkit-color-palette-wrapper
  .devkit-cp-wrapper
  .devkit-color-wrapper
  .devkit-rgb-text {
  font-size: 0.875rem;
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-light-blue {
  background: #0076c0;
  background: var(--light-blue);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-primary-blue {
  background: #003e7e;
  background: var(--primary-blue);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-lighter-grey {
  background: #e9eaed;
  background: var(--lighter-grey);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-slate {
  background: #333333;
  background: var(--slate);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-green {
  background: #22763b;
  background: var(--green);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-yellow {
  background: #fdb81e;
  background: var(--yellow);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-red {
  background: #c32026;
  background: var(--red);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-sky-blue {
  background: #02bfe7;
  background: var(--sky-blue);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-apple-green {
  background: #e7f4e4;
  background: var(--applegreen);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-varden {
  background: #fff1d2;
  background: var(--varden);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-tutu {
  background: #f9dede;
  background: var(--tutu);
}

.devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-light-cyan {
  background: #e1f3f8;
  background: var(--light-cyan);
}

@media screen and (min-width: 768px) {
  .devkit-color-palette-wrapper .devkit-cp-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 500px;
  }

  .devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-color-wrapper {
    display: flex;
    margin: 0 18px 18px 0;
  }

  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-light-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-primary-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-lighter-grey,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-slate,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-green,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-yellow,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-red,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-sky-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-apple-green,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-varden,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-tutu,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-light-cyan {
    width: 120px;
    height: 120px;
    margin-right: 12px;
  }

  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-color-name {
    width: auto;
    text-align: center;
  }

  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-color-hex,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-color-rgb {
    text-align: left;
  }
}

@media screen and (min-width: 960px) {
  .devkit-color-palette-wrapper .devkit-warning-message-wrapper {
    margin: 24px auto;
  }
  .devkit-color-palette-wrapper .devkit-cp-wrapper {
    max-width: 700px;
  }
  .devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-color-wrapper {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-color-palette-wrapper .devkit-cp-wrapper {
    max-width: 1200px;
  }

  .devkit-color-palette-wrapper .devkit-cp-wrapper .devkit-color-wrapper {
    margin-bottom: 36px;
  }

  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-light-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-primary-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-lighter-grey,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-slate,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-green,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-yellow,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-red,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-sky-blue,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-apple-green,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-varden,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-tutu,
  .devkit-color-palette-wrapper
    .devkit-cp-wrapper
    .devkit-color-wrapper
    .devkit-light-cyan {
    width: 150px;
    height: 150px;
    margin-right: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-color-palette-wrapper .devkit-cp-wrapper {
    max-width: 1400px;
  }
}

.devkit-changelog-content {
  display: block;
}

.devkit-changelog-content .devkit-release-head {
  border-top: 1px solid #6e7476;
  border-top: 1px solid var(--secondary-grey);
  padding-top: 12px;
}

.devkit-accordion-wrapper {
  width: 430px;
  margin: 0 0 24px 0;
}

.devkit-accordion-wrapper .devkit-accordion-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  background: #e9eaed;
  background: var(--lighter-grey);
  margin: 12px 0 0 0;
  padding: 6px 12px 9px 12px;
}

.devkit-accordion-wrapper .devkit-accordion-head .devkit-expand-collapse {
  margin-left: 6px;
  margin-top: 2px;
  color: #333333;
  color: var(--slate);
}

.devkit-accordion-wrapper .devkit-accordion-head .fa-chevron-right {
  float: left;
  color: #6e7476;
  color: var(--secondary-grey);
}

.devkit-accordion-wrapper .devkit-accordion-head:hover {
  background: #cccccc;
  background: var(--light-grey);
}

.devkit-accordion-wrapper .devkit-accordion-head.selected {
  background: #003e7e;
  background: var(--primary-blue);
  color: #ffffff;
  color: var(--white);
}

.devkit-accordion-wrapper
  .devkit-accordion-head.selected
  .devkit-expand-collapse {
  color: #ffffff;
  color: var(--white);
}

.devkit-accordion-wrapper .devkit-accordion-head.selected:hover {
  background: #0e76bd;
  background: var(--hover-blue);
}

.devkit-accordion-wrapper .devkit-accordion-body {
  display: none;
  border: solid 2px #e9eaed;
  border: solid 2px var(--lighter-grey);
  padding: 12px;
}

.devkit-accordion-wrapper .devkit-accordion-body p {
  line-height: 1.5rem;
}

@media screen and (min-width: 960px) {
  .devkit-accordion-wrapper {
    width: 500px;
  }
}

/* Following CSS rules cannot be altered. */

.form-button-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
}

.link-button {
  background: none;
  text-decoration: underline;
  color: #0d76bd;
  color: var(--ocean-blue);
  font-size: 1rem;
  margin: 6px;
}

.primary,
.secondary {
  color: #ffffff;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
  margin: 6px 6px 6px 0;
}

.primary {
  background: #003e7e;
  background: var(--primary-blue);
}

.secondary {
  background: #6e7476;
  background: var(--secondary-grey);
}

.disabled {
  margin: 6px 6px 6px 0;
  background: #d7dada;
  background: var(--cyan);
  color: #333333;
  color: var(--slate);
  opacity: 0.8;
}

.primary.mini,
.secondary.mini,
.disabled.mini {
  padding: 4px 5px 3px 5px;
  font-size: 0.75rem;
}

.primary.small,
.secondary.small,
.disabled.small {
  padding: 6px 7px 5px 7px;
  font-size: 0.75rem;
}

.primary.default,
.secondary.default,
.disabled.default {
  padding: 8px 15px 8px 15px;
  font-size: 0.875rem;
}

.primary.large,
.secondary.large,
.disabled.large {
  padding: 10px 15px 12px 15px;
  font-size: 1.125rem;
}

.primary:hover,
.secondary:hover {
  background: #0e76bd;
  background: var(--hover-blue);
}

/* Following CSS rules are used for demo purposes in this app. It can be altered. */

.devkit-buttons-wrapper .devkit-buttons-description {
  font-weight: 600;
}

.devkit-buttons-wrapper h2,
.devkit-buttons-wrapper .devkit-h2 {
  margin: 12px auto;
}

.devkit-buttons-wrapper h3:first-of-type,
.devkit-buttons-wrapper .devkit-h3:first-of-type {
  margin-top: 12px;
}

.devkit-buttons-wrapper p {
  margin-right: 12px;
}

.devkit-buttons-wrapper p a:link {
  color: #005690;
  color: var(--link-active);
}

.devkit-buttons-wrapper p a:hover {
  color: #000;
  color: var(--black);
  text-decoration: underline;
}

.devkit-buttons-wrapper p a:visited {
  color: #7a0098;
  color: var(--purple);
}

.devkit-buttons-wrapper .devkit-button-heading {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin: 24px auto 6px auto;
}

.devkit-buttons-wrapper button {
  margin-right: 24px;
  border: 0;
}

@media screen and (min-width: 960px) {
  .devkit-buttons-wrapper p {
    margin-right: 36px;
  }
}

.devkit-icons-table-wrapper {
  margin-bottom: 24px;
}

.devkit-icons-table-wrapper h3,
.devkit-icons-table-wrapper .devkit-h3 {
  margin-bottom: 12px;
}

.devkit-icons-table-wrapper p {
  margin: 0;
  line-height: 1.875rem;
}

.devkit-icons-table-wrapper .devkit-table {
  width: 70%;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 24px 0 0 0;
  padding: 0;
  table-layout: fixed;
  color: #333333;
  color: var(--slate);
}

.devkit-icons-table-wrapper .devkit-table .devkit-table-title {
  padding: 10px 9px 8px 18px;
  background: #0076c0;
  background: var(--light-blue);
  color: #ffffff;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

.devkit-icons-table-wrapper .devkit-table thead th {
  padding: 10px 10px 10px 18px;
  text-align: left;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  font-weight: 600;
  color: #002664;
  color: var(--dark-blue);
}

.devkit-icons-table-wrapper .devkit-table tbody td {
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  padding: 10px 10px 10px 18px;
}

.devkit-icons-table-wrapper .devkit-table tr:hover {
  font-weight: 600;
}

.devkit-icons-table-wrapper .devkit-table tr.devkit-table-row:nth-child(even) {
  background: #f4f6f9;
  background: var(--table-grey);
}

.devkit-icons-table-wrapper .devkit-table tr.devkit-table-row:nth-child(odd) {
  background: #ffffff;
  background: var(--white);
}

.devkit-icons-table-wrapper .devkit-table .fa {
  font-size: 1.5rem;
  /* padding-left: 18px; */
}

@media screen and (max-width: 600px) {
  .devkit-icons-table-wrapper .devkit-table {
    width: 100%;
  }
}

.devkit-main-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #333333;
  color: var(--slate);
}

.devkit-main-head .devkit-global-icon-wrapper:hover,
.devkit-main-head .devkit-global-icon-wrapper:focus {
  background: #cccccc;
  background: var(--light-grey);
}

.devkit-main-head .devkit-global-icon-wrapper {
  width: 65px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  padding: 9px 8px 9px 12px;
  color: #333333;
  color: var(--slate);
  cursor: pointer;
  border: solid 1px #d0d0d0;
  border: solid 1px var(--background-grey);
}

.devkit-main-head .devkit-global-icon-wrapper .fas.fa-print,
.devkit-main-head .devkit-global-icon-wrapper .fas.fa-share-alt {
  font-size: 1.125rem;
}

.devkit-main-head .devkit-global-icon-wrapper .devkit-icon-text {
  display: inline;
  padding-left: 10px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

@media screen and (min-width: 960px) {
  .devkit-main-head .devkit-global-icon-wrapper {
    margin-top: 4px;
  }
}

#devkit-skip-to-main {
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  margin-left: 0;
}

#devkit-skip-to-main a {
  position: absolute;
  left: -9999px;
  top: 4px;
  background: #cccccc;
  background: var(--light-grey);
  font-weight: 600;
}

#devkit-skip-to-main a:focus,
#devkit-skip-to-main a:active {
  position: relative;
  left: 2px;
  z-index: 100;
  height: auto;
  margin: 0;
  padding: 6px;
  text-decoration: none;
  outline: none;
}

.devkit-actions-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.devkit-actions-wrapper a:link,
.devkit-actions-wrapper a:hover,
.devkit-actions-wrapper a:visited {
  color: #333333;
  color: var(--slate);
  text-decoration: none;
}

.devkit-actions-wrapper .devkit-subscribe-icons-wrapper:hover,
.devkit-actions-wrapper .devkit-subscribe-icons-wrapper:focus {
  background: #cccccc;
  background: var(--light-grey);
}

.devkit-actions-wrapper .devkit-subscribe-icons-wrapper {
  position: relative;
  padding: 12px 8px 9px 12px;
  margin-top: 9px;
  cursor: pointer;
  border: solid 1px #d0d0d0;
  border: solid 1px var(--background-grey);
  color: #333333;
  color: var(--slate);
}

.devkit-actions-wrapper .devkit-subscribe-icons-wrapper .fa-envelope {
  position: absolute;
  font-size: 1.125rem;
}

.devkit-actions-wrapper .devkit-subscribe-icons-wrapper .fa-bell {
  position: absolute;
  top: 22px;
  right: 86px;
  font-size: 0.75rem;
}

.devkit-actions-wrapper .devkit-subscribe-icons-wrapper .devkit-icon-text {
  margin-left: 28px;
}

.devkit-version-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  margin: 12px 12px 0 0;
  font-size: 1rem;
  font-style: italic;
  color: #333333;
  color: var(--slate);
}

@media screen and (min-width: 960px) {
  .devkit-version-wrapper {
    margin: 6px 24px 0 0;
  }
}

.devkit-err-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 500px;
  margin-top: 12px;
}

.devkit-err-wrapper .devkit-err-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
  font-weight: 500;
}

.devkit-err-wrapper .devkit-err-message-wrapper .devkit-error-code {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.devkit-err-wrapper .devkit-err-message-wrapper .devkit-error-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.devkit-err-wrapper .devkit-err-message-wrapper .devkit-error-action-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .primary-link-button,
.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .secondary-link-button {
  margin: 6px 6px 6px 0;
  padding: 6px 12px;
  border: none;
  color: #ffffff;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .primary-link-button {
  background: #003e7e;
  background: var(--primary-blue);
}

.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .secondary-link-button {
  background: #6e7476;
  background: var(--secondary-grey);
}

.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .primary-link-button:hover,
.devkit-err-wrapper
  .devkit-err-message-wrapper
  .devkit-error-action-btns
  .secondary-link-button:hover {
  background: #0e76bd;
  background: var(--hover-blue);
}

@media screen and (min-width: 960px) {
  .devkit-err-wrapper {
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
  }
  .devkit-err-wrapper .devkit-err-message-wrapper {
    margin-left: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-err-wrapper {
    margin-top: 60px;
  }
}

.devkit-guidelines-wrapper .devkit-warning-message-wrapper {
  margin: 24px auto;
}

.devkit-guidelines-wrapper img {
  width: 90%;
  height: auto;
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 960px) {
  .devkit-guidelines-wrapper .devkit-desc-list li {
    line-height: 1.875rem;
  }

  .devkit-guidelines-wrapper h2,
  .devkit-guidelines-wrapper .devkit-h2 {
    margin-top: 24px;
  }

  .devkit-guidelines-wrapper h4,
  .devkit-guidelines-wrapper .devkit-h4 {
    margin: 0;
  }

  .devkit-guidelines-wrapper .devkit-warning-message-wrapper {
    margin: 36px auto;
  }

  .devkit-guidelines-wrapper
    .devkit-warning-message-wrapper
    .devkit-message-body {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-guidelines-wrapper img {
    width: 75%;
    margin-top: 36px;
    margin-bottom: 36px;
  }
}

.devkit-home-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  font-weight: 500;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-home-content p {
  margin: 6px 0;
}

.devkit-home-content .devkit-help-link {
  margin-bottom: 18px;
}

.devkit-home-content .devkit-help-link-bold {
  margin-top: 18px;
  font-weight: 600;
}

.devkit-modal {
  width: auto;
  font-size: 1rem;
}

.devkit-modal .devkit-modal-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: #0d76bd;
  background: var(--dialog-background);
  color: #ffffff;
  color: var(--white);
  font-weight: 700;
  padding: 0 15px;
}

.devkit-modal .devkit-modal-body {
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-top: none;
  padding: 15px;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-modal .devkit-modal-body h3,
.devkit-modal .devkit-modal-body .devkit-h3 {
  color: #0071bc;
  color: var(--navy-blue);
}

.devkit-modal .devkit-modal-body p {
  margin-top: 0;
}

.devkit-modal .devkit-modal-body .devkit-red-text {
  color: #c32026;
  color: var(--red);
}

.devkit-modal .devkit-modal-body .devkit-italic-text {
  font-weight: 600;
  font-style: italic;
  color: #c32026;
  color: var(--red);
}

.devkit-modal .devkit-modal-body .devkit-buttons-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .devkit-modal {
    width: auto;
  }
  .devkit-modal .devkit-modal-body {
    height: auto;
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-modal {
    width: 600px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-modal {
    width: 900px;
  }
}

.devkit-modal-wrapper {
  margin: 0 24px 12px 12px;
}

.devkit-modal-wrapper .devkit-buttons-wrapper button#modal-button,
.devkit-modal-wrapper .devkit-buttons-wrapper button.devkit-code-button {
  display: inline;
}

.devkit-modal-wrapper .devkit-modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  filter: alpha(opacity=50);
}

.devkit-modal-wrapper .modal {
  display: none;
}

.devkit-modal-wrapper .devkit-modal-overlay.active {
  display: block;
}

.devkit-modal-wrapper .modal.active {
  display: block;
  z-index: 200;
  border: 2px solid #797d7f;
  border: 2px solid var(--darker-grey);
  position: absolute;
  width: auto;
  height: auto;
  left: 1%;
  top: 0;
  overflow: auto;
}

.devkit-modal-wrapper .modal.active .devkit-modal .devkit-modal-body {
  background: #e9eaed;
  background: var(--lighter-grey);
  color: #333333;
  color: var(--slate);
}

@media screen and (min-width: 768px) {
  .devkit-modal-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-modal-wrapper {
    margin-left: 0;
  }
  .devkit-modal-wrapper h3,
  .devkit-modal-wrapper .devkit-h3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-modal-wrapper h3,
  .devkit-modal-wrapper .devkit-h3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .devkit-modal-wrapper .modal.active {
    left: 30%;
  }
}

.devkit-progressbar-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.devkit-progressbar-wrapper h3,
.devkit-progressbar-wrapper .devkit-h3 {
  margin-top: 0;
  margin-left: 12px;
}

.devkit-progressbar-wrapper .devkit-cb-wrapper {
  display: flex;
  flex-flow: row;
  margin-bottom: 24px;
}

.devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-circle-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 24px;
  padding-top: 33px;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .fa-check-circle,
.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .fa-times-circle {
  font-size: 2.25rem;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .fa-spinner {
  padding: 2px;
  border-radius: 50%;
  background: #fdb81e;
  background: var(--yellow);
  color: #333333;
  color: var(--slate);
  font-size: 1.875rem;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .fa-check-circle {
  color: #22763b;
  color: var(--green);
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .fa-times-circle {
  color: #c32026;
  color: var(--red);
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-ns-circle {
  width: 36px;
  height: 36px;
  background: #d0d0d0;
  background: var(--background-grey);
  border-radius: 50%;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-completion-bar,
.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-ns-bar,
.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-inprogress-bar,
.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-error-bar {
  width: 6px;
  height: 139px;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-completion-bar {
  background: #22763b;
  background: var(--green);
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-inprogress-bar {
  background: #fdb81e;
  background: var(--yellow);
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-ns-bar {
  background: #cccccc;
  background: var(--light-grey);
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-circle-wrapper
  .devkit-error-bar {
  background: #c32026;
  background: var(--red);
}

.devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-status-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-success-status {
  border: solid 2px #22763b;
  border: solid 2px var(--green);
  border-top: 0;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-success-status
  .devkit-status-header {
  padding-left: 6px;
  background: #22763b;
  background: var(--green);
  color: #ffffff;
  color: var(--white);
  font-weight: 600;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-progress-status {
  border: solid 2px #fdb81e;
  border: solid 2px var(--yellow);
  border-top: 0;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-progress-status
  .devkit-status-header {
  padding-left: 6px;
  background: #fdb81e;
  background: var(--yellow);
  color: #333333;
  color: var(--slate);
  font-weight: 600;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-error-status {
  border: solid 2px #c32026;
  border: solid 2px var(--red);
  border-top: 0;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-error-status
  .devkit-status-header {
  padding-left: 6px;
  background: #c32026;
  background: var(--red);
  color: #ffffff;
  color: var(--white);
  font-weight: 600;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-next-status {
  border: solid 2px #797d7f;
  border: solid 2px var(--darker-grey);
  border-top: 0;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-next-status
  .devkit-status-header {
  padding-left: 6px;
  background: #797d7f;
  background: var(--darker-grey);
  color: #ffffff;
  color: var(--white);
  font-weight: 600;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-status-text {
  width: 180px;
  height: 73px;
  padding: 6px;
  background: #e9eaed;
  background: var(--lighter-grey);
  color: #333333;
  color: var(--slate);
  font-size: 0.875rem;
  justify-content: center;
}

.devkit-progressbar-wrapper
  .devkit-cb-wrapper
  .devkit-status-wrapper
  .devkit-hr {
  width: 2px;
  height: 60px;
  background: #797d7f;
  background: var(--darker-grey);
}

.devkit-progressbar-wrapper .devkit-progressbar {
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 24px;
}

.devkit-progressbar-wrapper .devkit-progressbar .devkit-bar,
.devkit-progressbar-wrapper .devkit-progressbar .devkit-percentage {
  height: 20px;
}

.devkit-progressbar-wrapper .devkit-progressbar .devkit-bar {
  width: 30%;
  background: #002664;
  background: var(--dark-blue);
}

.devkit-progressbar-wrapper .devkit-progressbar .devkit-percentage {
  width: 70%;
  background: #e9eaed;
  background: var(--lighter-grey);
}

.devkit-progressbar-wrapper .devkit-loading {
  display: block;
  width: 100%;
  margin-bottom: 48px;
}

.devkit-progressbar-wrapper .devkit-loading .fa-spinner,
.devkit-progressbar-wrapper .devkit-loading .devkit-loading-text {
  display: inline;
}

.devkit-progressbar-wrapper .devkit-loading .fa-spinner {
  font-size: 1.5rem;
}

.devkit-progressbar-wrapper .devkit-loading .devkit-loading-text {
  margin-left: 12px;
  font-size: 1.125rem;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .devkit-progressbar-wrapper h3,
  .devkit-progressbar-wrapper .devkit-h3 {
    margin-left: 0;
  }

  .devkit-progressbar-wrapper .devkit-progressbar {
    width: 400px;
    margin-top: 24px;
  }
}

@media screen and (min-width: 960px) {
  .devkit-progressbar-wrapper h3:last-of-type,
  .devkit-progressbar-wrapper .devkit-h3:last-of-type {
    margin-left: 12px;
  }
  .devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-circle-wrapper {
    margin-bottom: 0;
    margin-right: 48px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-progressbar-wrapper {
    display: flex;
    flex-flow: column;
  }

  .devkit-progressbar-wrapper h3:last-of-type,
  .devkit-progressbar-wrapper .devkit-h3:last-of-type {
    margin-left: 0;
  }

  .devkit-progressbar-wrapper .devkit-cb-wrapper {
    display: flex;
    flex-flow: column;
    margin-bottom: 36px;
  }

  .devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-circle-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    order: 1;
    padding-left: 44px;
    padding-top: 0;
    margin-bottom: 24px;
    margin-right: 0;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-ns-circle {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-completion-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-ns-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-inprogress-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-error-bar {
    min-width: 99px;
    max-width: 99px;
    height: 6px;
  }

  .devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-status-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    order: 2;
  }
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-success-status
    .devkit-status-header,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-progress-status
    .devkit-status-header,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-error-status
    .devkit-status-header,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-next-status
    .devkit-status-header {
    padding-left: 0;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-status-text {
    min-width: 102px;
    max-width: 102px;
    height: 130px;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-hr {
    min-width: 18px;
    max-width: 18px;
    height: 2px;
  }

  .devkit-progressbar-wrapper .devkit-progressbar {
    width: 600px;
    margin-top: 36px;
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-progressbar-wrapper .devkit-cb-wrapper .devkit-circle-wrapper {
    padding-left: 70px;
    padding-top: 0;
    margin-bottom: 24px;
    margin-right: 0;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-ns-circle {
    min-width: 36px;
    height: 36px;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-completion-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-ns-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-inprogress-bar,
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-circle-wrapper
    .devkit-error-bar {
    min-width: 150px;
    height: 6px;
  }
  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-status-text {
    min-width: 150px;
    height: 90px;
  }

  .devkit-progressbar-wrapper
    .devkit-cb-wrapper
    .devkit-status-wrapper
    .devkit-hr {
    min-width: 24px;
    height: 2px;
  }
}

.devkit-tabs-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 12px;
}

@media screen and (min-width: 960px) {
  .devkit-tabs-wrapper {
    justify-items: flex-start;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-tabs-wrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-tabs-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Internal */

.devkit-bottom-bar-wrapper {
  height: 72px;
  padding: 4px 0;
  margin: 24px 12px 12px 12px;
  background: linear-gradient(#002663, #000933);
  background: linear-gradient(
    var(--bottom-bar-dark-blue),
    var(--bottom-bar-darker-blue)
  );
  border-radius: 10px;
}

.devkit-bottom-bar-wrapper .devkit-logo-wrapper {
  display: flex;
  align-items: center;
  margin-left: 18px;
  margin-top: 6px;
}

.devkit-bottom-bar-wrapper .devkit-logo-wrapper img {
  width: 60px;
  height: 60px;
}

.devkit-bottom-bar-wrapper .devkit-logo-wrapper .devkit-logo-title {
  color: #ffffff;
  color: var(--white);
  width: 200px;
  font-size: 1.125rem;
  font-weight: 600;
  margin-left: 6px;
}

@media screen and (min-width: 768px) {
  .devkit-bottom-bar-wrapper {
    height: 90px;
    padding: 4px 0;
    margin: 48px 24px 24px 24px;
  }
  .devkit-bottom-bar-wrapper .devkit-logo-wrapper img {
    width: 72px;
    height: 72px;
  }
  .devkit-bottom-bar-wrapper .devkit-logo-wrapper .devkit-logo-title {
    font-size: 1.5rem;
  }
}

.devkit-top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #ffffff;
  color: var(--white);
  background: #0076c0;
  background: var(--light-blue);
}

.devkit-top-bar span.devkit-top-bar-text {
  margin-left: 12px;
  font-size: 1rem;
  font-weight: 400;
}

.devkit-top-bar span.devkit-top-bar-text span.devkit-faa-text {
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .devkit-top-bar {
    height: 36px;
  }
  .devkit-top-bar span.devkit-top-bar-text {
    margin-left: 12px;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 960px) {
  .devkit-top-bar {
    height: 36px;
  }
  .devkit-top-bar span.devkit-top-bar-text {
    margin-left: 24px;
    font-size: 1.5rem;
  }
}

/* External */

.devkit-ext-btmbar-wrapper {
  width: 100%;
  background: linear-gradient(#ffffff, #f3f3f3);
  background: linear-gradient(var(--white), var(--ext-dot-footer-gradient));
  padding: 12px 0 24px;
}

.devkit-ext-btmbar-wrapper .devkit-ext-btmbar,
.devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: auto;
  margin: 0 auto;
}

.devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-content,
.devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar .btmbar-content {
  grid-column: 1 / span 7;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 6px;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper
  p,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper
  p {
  line-height: 1rem;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper
  img,
.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper
  .faa-addr,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper
  img,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper
  .faa-addr {
  display: inline-block;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper
  img,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper
  img {
  width: 63px;
  height: 64px;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .logo-addr-wrapper
  .faa-addr,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .logo-addr-wrapper
  .faa-addr {
  padding-left: 9px;
}

.devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-content .btmbar-col h2,
.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .btmbar-col
  .devkit-h2,
.devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar .btmbar-content .btmbar-col h2,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  .devkit-h2 {
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-content .btmbar-col ul,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul {
  list-style-type: none;
  padding-left: 0;
}

.devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-content .btmbar-col ul li,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li {
  display: block;
  margin-top: 6px;
  font-size: 0.9375rem;
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:link,
.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:hover,
.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:visited,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:link,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:hover,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:visited {
  color: #005690;
  color: var(--link-active);
}

.devkit-ext-btmbar-wrapper
  .devkit-ext-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:hover,
.devkit-ext-btmbar-wrapper
  .devkit-ext-fw-btmbar
  .btmbar-content
  .btmbar-col
  ul
  li
  a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 960px) {
  .devkit-ext-btmbar-wrapper {
    padding: 6px 0 12px;
  }

  .devkit-ext-btmbar-wrapper .devkit-ext-btmbar {
    width: 100%;
  }
  .devkit-ext-btmbar-wrapper .devkit-ext-btmbar .logo-addr-wrapper {
    margin-left: 24px;
  }
  .devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-col:last-of-type {
    margin-right: 24px;
  }

  .devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 1%;
  }

  .devkit-ext-btmbar-wrapper .devkit-ext-btmbar,
  .devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-ext-btmbar-wrapper .devkit-ext-btmbar .btmbar-content,
  .devkit-ext-btmbar-wrapper .devkit-ext-fw-btmbar .btmbar-content {
    grid-column: 1 / span 23;
    grid-row: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
  }
  .devkit-ext-btmbar-wrapper
    .devkit-ext-btmbar
    .btmbar-content
    .logo-addr-wrapper
    img,
  .devkit-ext-btmbar-wrapper
    .devkit-ext-fw-btmbar
    .btmbar-content
    .logo-addr-wrapper
    img {
    width: 96px;
    height: 96px;
  }
  .devkit-ext-btmbar-wrapper
    .devkit-ext-btmbar
    .btmbar-content
    .logo-addr-wrapper
    .faa-addr,
  .devkit-ext-btmbar-wrapper
    .devkit-ext-fw-btmbar
    .btmbar-content
    .logo-addr-wrapper
    .faa-addr {
    padding-left: 6px;
  }
}

.devkit-dot-microbar-wrapper {
  background: #15396c;
  background: var(--ext-microhead-background);
  color: #ffffff;
  color: var(--white);
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: auto;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar a:link,
.devkit-dot-microbar-wrapper .devkit-dot-microbar a:visited,
.devkit-dot-microbar-wrapper .devkit-dot-microbar a:hover,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar a:link,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar a:visited,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar a:hover {
  color: #ffffff;
  color: var(--white);
  text-decoration: none;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-logo-wrapper,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-logo-wrapper {
  grid-column: 1 / span 7;
  grid-row: 1 / span 1;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-logo-wrapper img,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-logo-wrapper img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  background: #ffffff;
  background: var(--white);
  border-radius: 50%;
}

.devkit-dot-microbar-wrapper
  .devkit-dot-microbar
  .dot-logo-wrapper
  .dot-logo-title,
.devkit-dot-microbar-wrapper
  .devkit-fw-dot-microbar
  .dot-logo-wrapper
  .dot-logo-title {
  margin-left: 2px;
  font-size: 0.875rem;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-links-wrapper,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-links-wrapper {
  grid-column: 1 / span 7;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 12px;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-links-wrapper ul,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-links-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

.devkit-dot-microbar-wrapper
  .devkit-dot-microbar
  .dot-links-wrapper
  ul
  li:last-of-type,
.devkit-dot-microbar-wrapper
  .devkit-fw-dot-microbar
  .dot-links-wrapper
  ul
  li:last-of-type {
  margin-right: 0;
}

.devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-links-wrapper ul li,
.devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-links-wrapper ul li {
  display: inline;
  margin-right: 18px;
  font-size: 0.875rem;
}

@media screen and (min-width: 960px) {
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar {
    width: 960px;
    margin: 0 auto;
    padding: 0 1%;
  }
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-logo-wrapper {
    margin-left: 0;
  }
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-links-wrapper {
    margin-right: 0;
  }
  .devkit-dot-microbar-wrapper .devkit-dot-microbar {
    width: 100%;
  }
  .devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-logo-wrapper {
    margin-left: 28px;
  }
  .devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-links-wrapper {
    margin-left: 0;
    margin-right: 36px;
  }

  .devkit-dot-microbar-wrapper .devkit-dot-microbar,
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 30px;
  }

  .devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-logo-wrapper,
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-logo-wrapper {
    grid-column: 1 / span 9;
    grid-row: 1;
  }

  .devkit-dot-microbar-wrapper .devkit-dot-microbar .dot-links-wrapper,
  .devkit-dot-microbar-wrapper .devkit-fw-dot-microbar .dot-links-wrapper {
    grid-column: 14 / span 10;
    grid-row: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

/* HEADER & HEADER SUB COMPONENTS CSS */

/* Common */

.devkit-greeting-wrapper {
  display: none;
  /* Following CSS rules are used in larger screens and in hamburger menu. */
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  background: transparent;
}

@media screen and (min-width: 768px) {
  .devkit-greeting-wrapper {
    display: none;
    font-size: 1.125rem;
  }
}

.devkit-mobile-menu {
  width: 44px;
  height: 52px;
  display: flex;
  justify-content: center;
}

.devkit-mobile-menu .devkit-icon-wrapper {
  display: block;
  cursor: pointer;
}

.devkit-mobile-menu .devkit-icon-wrapper .fa-bars {
  display: block;
  width: 36px;
  padding-left: 13px;
  padding-top: 6px;
  font-size: 1.5rem;
}

.devkit-mobile-menu .devkit-icon-wrapper .devkit-menu-text {
  display: block;
  padding: 4px 4px 12px 6px;
  font-size: 0.875rem;
  font-weight: 600;
}

.devkit-menu-overlay {
  display: none;
}

.devkit-mobile-menu-items {
  display: none;
}

.devkit-menu-overlay.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  background: var(--white);
  color: #333333;
  color: var(--slate);
  overflow: auto;
  z-index: 30;
}

.devkit-menu-overlay.active .devkit-greeting-wrapper {
  display: block;
  width: 260px;
  word-wrap: break-word;
}

.devkit-menu-overlay.active .devkit-greeting-wrapper p {
  padding-left: 6px;
}

.devkit-menu-overlay.active .devkit-close-menu {
  display: block;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 1.5rem;
  color: #353535;
  color: var(--charcoal);
  cursor: pointer;
}

.devkit-menu-overlay.active .devkit-mobile-menu-items.active {
  display: block;
  overflow: hidden;
}

.devkit-menu-overlay.active .devkit-mobile-menu-items.active li.devkit-nav-link,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  li.devkit-inner-nav-link {
  list-style-type: none;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links {
  position: relative;
  display: block;
  padding-left: 6px;
  padding-right: 6px;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link-nosub {
  display: block;
  line-height: 2.25rem;
  border-bottom: solid 1px #e9eaed;
  border-bottom: solid 1px var(--lighter-grey);
  margin-bottom: 9px;
  font-weight: 600;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link
  a:link,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link
  a:visited,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link
  a:hover,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link-nosub
  a:link,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link-nosub
  a:visited,
.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link-nosub
  a:hover {
  color: #353535;
  color: var(--charcoal);
  text-decoration: none;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  ul.devkit-nav-links
  li.devkit-nav-link
  .devkit-main-menu-link:after {
  content: "\25bc";
  color: #002664;
  color: var(--dark-blue);
  padding-left: 2px;
  font-size: 1rem;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  li.devkit-nav-link
  ul.submenu-nav {
  display: none;
}

.devkit-menu-overlay.active
  .devkit-mobile-menu-items.active
  li.devkit-inner-nav-link {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .devkit-menu-overlay.active {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    background: var(--white);
    overflow: hidden;
  }

  .devkit-menu-overlay.active .devkit-greeting-wrapper {
    display: block;
    width: 600px;
  }
  .devkit-menu-overlay.active .devkit-greeting-wrapper p {
    padding-left: 18px;
  }

  .devkit-menu-overlay.active .devkit-close-menu {
    top: 12px;
    right: 22px;
    font-size: 1.5rem;
    color: #353535;
    color: var(--charcoal);
  }
  .devkit-menu-overlay.active
    .devkit-mobile-menu-items.active
    ul.devkit-nav-links {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 960px) {
  .devkit-mobile-menu {
    display: none;
  }
}

.devkit-app-title,
.devkit-ext-app-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125rem;
  margin-top: 14px;
}

.devkit-app-title a:link,
.devkit-app-title a:hover,
.devkit-app-title a:visited {
  color: #002664;
  color: var(--dark-blue);
  text-decoration: none;
}

.devkit-ext-app-title a:link,
.devkit-ext-app-title a:hover,
.devkit-ext-app-title a:visited {
  color: #ffffff;
  color: var(--white);
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .devkit-app-title,
  .devkit-ext-app-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin-top: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-app-title,
  .devkit-ext-app-title {
    font-size: 1.625rem;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-app-title,
  .devkit-ext-app-title {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-site-logo,
  .devkit-ext-app-title {
    font-size: 2.5rem;
    font-weight: 400;
  }
}

.devkit-search-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
}

.devkit-search-wrapper button {
  padding: 0;
  margin-left: -5px;
}

.devkit-search-wrapper input[type="text"] {
  width: 270px;
  height: 22px;
  border-left: 2px solid #d0d0d0;
  border-left: 2px solid var(--background-grey);
  border-bottom: 2px solid #d0d0d0;
  border-bottom: 2px solid var(--background-grey);
  border-top: 2px solid #d0d0d0;
  border-top: 2px solid var(--background-grey);
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.devkit-search-wrapper input::-moz-placeholder {
  padding-left: 6px;
}

.devkit-search-wrapper input:-ms-input-placeholder {
  padding-left: 6px;
}

.devkit-search-wrapper input::placeholder {
  padding-left: 6px;
}

.devkit-search-wrapper .devkit-btn-search,
.devkit-search-wrapper .devkit-close-search {
  display: none;
}

.devkit-search-wrapper .devkit-search-icon {
  width: 32px;
  height: 28px;
  padding-right: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  border: 0px solid #e9eaed;
  border: 0px solid var(--lighter-grey);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #d0d0d0;
  background: var(--background-grey);
  color: #005690;
  color: var(--whaleblue);
  vertical-align: bottom;
}

@media screen and (min-width: 768px) {
  .devkit-search-wrapper {
    display: block;
    padding-bottom: 60px;
  }
  .devkit-search-wrapper input[type="text"] {
    width: 500px;
  }
}

@media screen and (min-width: 960px) {
  .devkit-search-wrapper {
    display: none;
    position: absolute;
    top: 102px;
    right: 24px;
    z-index: 10;
    padding-bottom: 0;
    border-bottom: 0;
  }
  .devkit-search-wrapper input[type="text"] {
    width: 380px;
  }

  .devkit-search-wrapper .devkit-search-icon {
    display: none;
  }

  .devkit-search-wrapper .devkit-btn-search,
  .devkit-search-wrapper .devkit-close-search {
    display: inline;
    font-size: 0.875rem;
    font-weight: 600;
    background: #d0d0d0;
    background: var(--background-grey);
    border: 0px solid #e9eaed;
    border: 0px solid var(--lighter-grey);
    color: #005690;
    color: var(--whaleblue);
    vertical-align: bottom;
  }

  .devkit-search-wrapper .devkit-btn-search {
    width: 70px;
    height: 28px;
    border: 0px solid #e9eaed;
    border: 0px solid var(--lighter-grey);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .devkit-search-wrapper .devkit-close-search {
    width: 30px;
    height: 28px;
    border-radius: 5px;
    margin-left: 1px;
  }

  .devkit-search-wrapper .devkit-close-search .fa-times {
    padding-top: 2px;
  }
}

.devkit-toolbar-wrapper,
.devkit-ext-toolbar-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  z-index: 8;
  cursor: pointer;
}

.devkit-toolbar-wrapper .devkit-tool-bar-text,
.devkit-ext-toolbar-wrapper .devkit-tool-bar-text {
  display: none;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon {
  display: flex;
  align-items: center;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon .devkit-tool-bar-text,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .devkit-tool-bar-text {
  padding-left: 4px;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon a:link,
.devkit-toolbar-wrapper .devkit-toolbar-icon a:visited,
.devkit-toolbar-wrapper .devkit-toolbar-icon a:hover,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:link,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:visited,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:hover {
  text-decoration: none;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-toolbar-wrapper .devkit-toolbar-icon .devkit-search-text,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .devkit-search-text {
  display: none;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle {
  font-size: 1.25rem;
}

.devkit-toolbar-wrapper #devkit-help-icon-wrapper,
.devkit-ext-toolbar-wrapper #devkit-help-icon-wrapper {
  position: relative;
}

.devkit-toolbar-wrapper #devkit-help-icon-wrapper .devkit-help-content-wrapper,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper {
  font-family: "Helvetica Neue", Helvetica, sans-serif, Arial;
  display: none;
  position: absolute;
  top: 60px;
  z-index: 51;
  width: 320px;
  height: auto;
  padding: 0 24px 24px 24px;
  background-color: #f4f6f9;
  background-color: var(--table-grey);
  border: 1px solid #797d7f;
  border: 1px solid var(--darker-grey);
  border-radius: 6px;
  box-shadow: 0 0 10px #797d7f;
  box-shadow: 0 0 10px var(--darker-grey);
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper {
  display: block;
  padding-bottom: 18px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title {
  font-weight: 600;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content {
  font-weight: 400;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-title,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper
  .item-content {
  display: inline;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
  padding: 6px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper:first-of-type,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper:first-of-type,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper:first-of-type,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper:first-of-type {
  margin-top: 18px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper:last-of-type,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper:last-of-type,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper
  .devkit-help-item-wrapper:last-of-type,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper
  .devkit-help-item-wrapper:last-of-type {
  padding-bottom: 12px;
}

.devkit-toolbar-wrapper #devkit-help-icon-wrapper .devkit-help-content-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper {
  right: -9px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper {
  right: -16px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:before,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:before,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:before,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:before {
  content: "";
  position: absolute;
  top: -42px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 32px solid rgba(125, 125, 127, 0.5);
  z-index: 2;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:after,
.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:after,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:after,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:after {
  content: "";
  position: absolute;
  top: -32px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom: 25px solid #f4f6f9;
  border-bottom: 25px solid var(--table-grey);
  z-index: 3;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:before,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:before {
  right: 10px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:before,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:before {
  right: 20px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:after,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-help-content-wrapper:after {
  right: 12px;
}

.devkit-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:after,
.devkit-ext-toolbar-wrapper
  #devkit-help-icon-wrapper
  .devkit-ext-help-content-wrapper:after {
  right: 22px;
}

.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
.devkit-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle {
  color: #002664;
  color: var(--dark-blue);
}

.devkit-toolbar-wrapper .devkit-tool-bar-text {
  color: #002664;
  color: var(--dark-blue);
}

.devkit-toolbar-wrapper .devkit-tool-bar-text.active {
  color: #002664;
  color: var(--dark-blue);
}

.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-search,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle {
  color: #ffffff;
  color: var(--white);
}

.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:active .fa-user,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:active .fa-user-alt,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:hover .fa-user,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:hover .fa-user-alt,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:visited .fa-user,
.devkit-ext-toolbar-wrapper .devkit-toolbar-icon a:visited .fa-user-alt {
  color: #ffffff;
  color: var(--white);
}

.devkit-ext-toolbar-wrapper .devkit-tool-bar-text.active {
  color: #ffffff;
  color: var(--white);
}

.devkit-ext-toolbar-wrapper a:link .devkit-tool-bar-text,
.devkit-ext-toolbar-wrapper a:hover .devkit-tool-bar-text,
.devkit-ext-toolbar-wrapper a:visited .devkit-tool-bar-text {
  color: #ffffff;
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .devkit-toolbar-wrapper .devkit-toolbar-icon .fa-search,
  .devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user,
  .devkit-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
  .devkit-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-search,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-user-alt,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-question-circle {
    font-size: 1.5rem;
  }

  .devkit-toolbar-wrapper .devkit-tool-bar-text,
  .devkit-ext-toolbar-wrapper .devkit-tool-bar-text {
    display: inline;
    padding-right: 6px;
    font-size: 1rem;
    font-weight: 600;
  }
}

@media screen and (min-width: 960px) {
  .devkit-toolbar-wrapper .devkit-toolbar-icon .fa-search,
  .devkit-toolbar-wrapper .devkit-toolbar-icon .devkit-search-text,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .fa-search,
  .devkit-ext-toolbar-wrapper .devkit-toolbar-icon .devkit-search-text {
    display: inline;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper {
    right: 20px;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper {
    right: 2px;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:before,
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:before,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:before,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:before {
    content: "";
    position: absolute;
    top: -42px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 32px solid rgba(125, 125, 127, 0.5);
    z-index: 2;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:after,
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:after,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:after,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:after {
    content: "";
    position: absolute;
    top: -32px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: 25px solid #f4f6f9;
    border-bottom: 25px solid var(--table-grey);
    z-index: 3;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:before,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:before {
    right: 51px;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:before,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:before {
    right: 20px;
  }

  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:after,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-help-content-wrapper:after {
    right: 53px;
  }
  .devkit-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:after,
  .devkit-ext-toolbar-wrapper
    #devkit-help-icon-wrapper
    .devkit-ext-help-content-wrapper:after {
    right: 22px;
  }
}

/* Internal */

header {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: 40px 12px 32px;
}

header .devkit-mobile-menu {
  grid-column: 1;
  grid-row: 1 / span 1;
  color: #002664;
  color: var(--dark-blue);
}

header .devkit-mobile-menu .fa-bars {
  color: #002664;
  color: var(--dark-blue);
}

header .devkit-app-title {
  grid-column: 2 / span 4;
  grid-row: 1;
  display: flex;
  align-items: center;
}

header {
  .devkit-greeting-wrapper {
    display: none;
    color: #333333;
    color: var(--slate);
  }
  .devkit-greeting-wrapper span.devkit-greeting {
    font-weight: 600;
    color: #002664;
    color: var(--dark-blue);
  }
}

header .devkit-search-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
}

header .devkit-toolbar-wrapper {
  grid-column: 7 / span 1;
  grid-row: 1;
  padding-left: 12px;
}

@media screen and (min-width: 768px) {
  header {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 60px 12px 40px;
    margin: 0 auto;
  }

  header .devkit-mobile-menu {
    grid-column: 1;
    grid-row: 1 / span 1;
  }

  header .devkit-app-title {
    grid-column: 5 / span 11;
    grid-row: 1 / span 1;
    align-items: flex-start;
  }
  header {
    .devkit-greeting-wrapper {
      display: none;
    }
  }

  header .devkit-search-wrapper {
    display: block;
    grid-column: 2 / span 21;
    grid-row: 3;
  }

  header .devkit-toolbar-wrapper {
    grid-column: 17 / span 7;
    grid-row: 1 / span 1;
  }
}

@media screen and (min-width: 960px) {
  header {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 36px 12px 36px;
    margin: 0 auto;
  }

  header .devkit-mobile-menu {
    display: none;
  }

  header .devkit-app-title {
    grid-column: 1 / span 11;
    grid-row: 1 / span 3;
    margin-left: 24px;
  }

  header .devkit-greeting-wrapper {
    display: flex;
    justify-content: flex-end;
    grid-column: 13 / span 11;
    grid-row: 1 / span 1;
    margin-right: 20px;
  }

  header .devkit-search-wrapper {
    display: none;
  }

  header .devkit-toolbar-wrapper {
    grid-column: 17 / span 7;
    grid-row: 3;
    padding-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  header {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 36px 12px 40px;
    margin: 0 auto;
  }

  header .devkit-app-title {
    grid-column: 1 / span 11;
    grid-row: 1 / span 3;
  }

  header .devkit-greeting-wrapper {
    grid-column: 15 / span 9;
    grid-row: 1 / span 1;
    margin-right: 12px;
  }

  header .devkit-toolbar-wrapper {
    grid-column: 18 / span 6;
    grid-row: 3;
  }
}

@media screen and (min-width: 1600px) {
  header {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 36px 12px 36px;
  }

  header .devkit-app-title {
    grid-column: 1 / span 9;
    grid-row: 1 / span 3;
  }

  header .devkit-greeting-wrapper {
    grid-column: 18 / span 6;
    grid-row: 1 / span 1;
  }

  header .devkit-toolbar-wrapper {
    grid-column: 20 / span 4;
    grid-row: 3;
  }
}

.header-links-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .header-links-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .header-links-wrapper ul.top-links {
    display: block;
    padding-left: 0;
  }
  .header-links-wrapper ul.top-links li.dropdown-link {
    padding: 2px 6px;
    border-radius: 4px;
    background: #353535;
    background: var(--charcoal);
    color: #ffffff;
    color: var(--white);
    cursor: pointer;
  }
  .header-links-wrapper ul.top-links li {
    display: inline-block;
    margin-right: 24px;
    font-weight: 700;
    list-style-type: none;
    line-height: 1.5rem;
  }
  .header-links-wrapper ul.top-links li a:link,
  .header-links-wrapper ul.top-links li a:visited {
    color: #333333;
    color: var(--slate);
    /** These are needed to avoid li items shifting to the right when hovering the link. **/
    padding: 6px;
    border-radius: 4px;
    background: #ffffff;
    background: var(--white);
    text-decoration: none;
  }
  .header-links-wrapper ul.top-links li a:hover {
    padding: 6px;
    border-radius: 4px;
    background: #353535;
    background: var(--charcoal);
    color: #ffffff;
    color: var(--white);
  }
}

/* External */

div.ext-header-wrapper {
  border-bottom: 5px solid #b28706;
  border-bottom: 5px solid var(--ext-header-top-border);
}

div.ext-header-wrapper .ext-header {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: 40px 12px 32px;
  width: auto;
  margin: 0 auto;
  padding-top: 6px;
  padding-bottom: 6px;
}

div.ext-header-wrapper .ext-header .devkit-mobile-menu {
  grid-column: 1;
  grid-row: 3 / span 1;
  color: #ffffff;
  color: var(--white);
}

div.ext-header-wrapper .ext-header .devkit-mobile-menu .fa-bars {
  color: #ffffff;
  color: var(--white);
}

div.ext-header-wrapper .ext-header .devkit-ext-site-logo {
  grid-column: 1 / span 3;
  grid-row: 1;
  margin-left: 12px;
}

div.ext-header-wrapper .ext-header .devkit-ext-app-title {
  grid-column: 3 / span 3;
  grid-row: 1;
  display: flex;
  align-items: center;
}

div.ext-header-wrapper .ext-header {
  .devkit-greeting-wrapper {
    display: none;
    color: #ffffff;
    color: var(--white);
  }
  .devkit-greeting-wrapper span.devkit-greeting {
    font-weight: 600;
    color: #ffffff;
    color: var(--white);
  }
}

div.ext-header-wrapper .ext-header .devkit-search-wrapper {
  display: flex;
  grid-column: 1 / span 7;
  grid-row: 3;
}

div.ext-header-wrapper .ext-header .ext-head-nav-wrapper {
  display: none;
}

div.ext-header-wrapper .ext-header .devkit-ext-toolbar-wrapper {
  grid-column: 6 / span 2;
  grid-row: 1;
  padding-right: 6px;
  color: var(-white);
}

@media screen and (min-width: 768px) {
  div.ext-header-wrapper .ext-header {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 60px 12px 40px;
  }
  div.ext-header-wrapper .ext-header .devkit-ext-mobile-menu {
    grid-column: 1;
    grid-row: 3 / span 1;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-site-logo {
    grid-column: 1 / span 7;
    grid-row: 1 / span 1;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-app-title {
    grid-column: 6 / span 10;
    grid-row: 1 / span 1;
    align-items: flex-start;
  }
  div.ext-header-wrapper .ext-header {
    .devkit-greeting-wrapper {
      display: none;
    }
  }

  div.ext-header-wrapper .ext-header .devkit-search-wrapper {
    display: block;
    grid-column: 4 / span 19;
    grid-row: 3;
  }

  div.ext-header-wrapper .ext-header .ext-head-nav-wrapper {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-toolbar-wrapper {
    grid-column: 17 / span 7;
    grid-row: 1 / span 1;
  }
}

@media screen and (min-width: 960px) {
  div.ext-header-wrapper .ext-header {
    grid-template-rows: 36px 12px 36px;
    padding-top: 0;
    padding-bottom: 0;
  }
  div.ext-header-wrapper .ext-header .mobile-menu {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-site-logo {
    grid-column: 1 / span 7;
    grid-row: 1 / span 3;
    margin-left: 14px;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-app-title {
    grid-column: 6 / span 10;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-header .devkit-greeting-wrapper {
    display: flex;
    justify-content: flex-end;
    grid-column: 13 / span 11;
    grid-row: 1 / span 1;
    margin-right: 20px;
  }

  div.ext-header-wrapper .ext-header .devkit-search-wrapper {
    display: none;
    top: 80px;
    right: 24px;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-toolbar-wrapper {
    grid-column: 17 / span 7;
    grid-row: 3 / span 1;
    padding-left: 0;
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 1280px) {
  div.ext-header-wrapper .ext-header {
    grid-template-rows: 36px 12px 36px;
  }
  div.ext-header-wrapper .ext-header .mobile-menu {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-site-logo {
    grid-column: 1 / span 5;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-app-title {
    grid-column: 7 / span 9;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-header .devkit-greeting-wrapper {
    display: flex;
    justify-content: flex-end;
    grid-column: 15 / span 9;
    grid-row: 1 / span 1;
    margin-right: 36px;
  }

  div.ext-header-wrapper .ext-header .devkit-search-wrapper {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-toolbar-wrapper {
    grid-column: 18 / span 6;
    grid-row: 3 / span 1;
    padding-left: 0;
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 1600px) {
  div.ext-header-wrapper .ext-header {
    grid-template-rows: 36px 12px 36px;
  }
  div.ext-header-wrapper .ext-header .mobile-menu {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-site-logo {
    grid-column: 1 / span 4;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-app-title {
    grid-column: 7 / span 9;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-header .devkit-greeting-wrapper {
    grid-column: 18 / span 6;
    grid-row: 1 / span 1;
    display: flex;
    justify-content: flex-end;
  }

  div.ext-header-wrapper .ext-header .devkit-search-wrapper {
    display: none;
  }

  div.ext-header-wrapper .ext-header .devkit-ext-toolbar-wrapper {
    grid-column: 19 / span 5;
    grid-row: 3 / span 1;
    padding-left: 0;
  }
}

.devkit-ext-site-logo {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.devkit-ext-site-logo img {
  display: none;
}

.devkit-ext-site-logo .devkit-logo-title {
  color: #ffffff;
  color: var(--white);
  width: 200px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125rem;
}

@media screen and (min-width: 768px) {
  .devkit-ext-site-logo .devkit-logo-title {
    font-size: 1rem;
  }
}

@media screen and (min-width: 960px) {
  .devkit-ext-site-logo {
    margin-top: 0;
  }
  .devkit-ext-site-logo img {
    display: inline;
    width: 84px;
    height: 84px;
    padding-bottom: 6px;
  }

  .devkit-ext-site-logo .devkit-logo-title {
    font-size: 2.25rem;
    line-height: 1.5rem;
  }
}

div.ext-header-wrapper {
  background: #003e7e;
  background: var(--external-dark-blue);
  color: #ffffff;
  color: var(--white);
}

div.ext-header-wrapper .ext-fw-header {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: 40px 12px 32px;
  width: auto;
  margin: 0 auto;
}

div.ext-header-wrapper .ext-fw-header .devkit-mobile-menu {
  grid-column: 1;
  grid-row: 3 / span 1;
  color: #ffffff;
  color: var(--white);
}

div.ext-header-wrapper .ext-fw-header .devkit-mobile-menu .fa-bars {
  color: #ffffff;
  color: var(--white);
}

div.ext-header-wrapper .ext-fw-header .devkit-ext-site-logo {
  grid-column: 1 / span 2;
  grid-row: 1;
}

div.ext-header-wrapper .ext-fw-header {
  .devkit-greeting-wrapper {
    display: none;
    color: #ffffff;
    color: var(--white);
  }
  .devkit-greeting-wrapper span.devkit-greeting {
    font-weight: 600;
    color: #ffffff;
    color: var(--white);
  }
}

div.ext-header-wrapper .ext-fw-header .devkit-ext-fw-search-wrapper {
  display: flex;
  grid-column: 1 / span 7;
  grid-row: 3;
}

div.ext-header-wrapper .ext-fw-header .devkit-ext-toolbar-wrapper {
  grid-column: 6 / span 2;
  grid-row: 1;
  padding-right: 6px;
  color: var(-white);
}

@media screen and (min-width: 768px) {
  div.ext-header-wrapper .ext-fw-header {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: 60px 12px 40px;
  }
  div.ext-header-wrapper .ext-fw-header .devkit-ext-mobile-menu {
    grid-column: 1;
    grid-row: 3 / span 1;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-ext-site-logo {
    grid-column: 1 / span 7;
    grid-row: 1 / span 1;
  }
  div.ext-header-wrapper .ext-fw-header {
    .devkit-greeting-wrapper {
      display: none;
    }
  }

  div.ext-header-wrapper .ext-fw-header .devkit-ext-fw-search-wrapper {
    display: block;
    grid-column: 4 / span 19;
    grid-row: 3;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-ext-toolbar-wrapper {
    grid-column: 17 / span 7;
    grid-row: 1 / span 1;
  }
}

@media screen and (min-width: 960px) {
  div.ext-header-wrapper .ext-fw-header {
    grid-template-rows: 36px 14px 36px;
    width: 960px;
    margin: 0 auto;
    padding: 0 1%;
  }
  div.ext-header-wrapper .ext-fw-header .mobile-menu {
    display: none;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-ext-site-logo {
    grid-column: 1 / span 6;
    grid-row: 1 / span 3;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-greeting-wrapper {
    display: flex;
    justify-content: flex-end;
    grid-column: 13 / span 11;
    grid-row: 1 / span 1;
    margin-right: 6px;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-search-wrapper {
    display: none;
    top: 80px;
    right: 445px;
  }

  div.ext-header-wrapper .ext-fw-header .devkit-ext-toolbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 17 / span 7;
    grid-row: 3 / span 1;
    padding: 0 0 0 12px;
  }
}

.ext-head-nav-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .ext-head-nav-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .ext-head-nav-wrapper ul.ext-head-nav-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    margin: 0;
  }
  .ext-head-nav-wrapper ul.ext-head-nav-items li.ext-head-nav-item {
    padding: 2px 6px;
    border-radius: 4px;
    color: #ffffff;
    color: var(--white);
    cursor: pointer;
    font-weight: 700;
    list-style-type: none;
  }
  .ext-head-nav-wrapper ul.ext-head-nav-items li.ext-head-nav-item a:link,
  .ext-head-nav-wrapper ul.ext-head-nav-items li.ext-head-nav-item a:visited {
    color: #ffffff;
    color: var(--white);
    /** These are needed to avoid li items shifting to the right when hovering the link. **/
    padding: 6px;
    border-radius: 4px;
    background: var(--external-head-active-background);
    text-decoration: none;
  }
  .ext-head-nav-wrapper ul.ext-head-nav-items li.ext-head-nav-item a:hover {
    padding: 6px;
    border-radius: 4px;
    background: var(--external-head-active-background);
    color: #ffffff;
    color: var(--white);
  }
  .ext-head-nav-wrapper ul.ext-head-nav-items li.faa-other {
    background: #886a05;
    background: var(--external-gold);
    position: relative;
  }
  .ext-head-nav-wrapper ul.ext-head-nav-items li.faa-other ul.ext-nav-dropdown {
    display: none;
    list-style-type: none;
  }
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown {
    display: block;
    width: 275px;
    background: rgba(255, 255, 255, 0.98);
    border: 3px solid #886a05;
    border: 3px solid var(--external-gold);
    border-radius: 4px 0 4px 4px;
    margin: 0;
    padding-left: 0;
    position: absolute;
    right: 0;
    z-index: 60;
  }
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown
    li.ext-head-submenu-item {
    display: block;
    padding: 6px;
    color: #333333;
    color: var(--slate);
    border-bottom: 1px solid #dddddd;
    border-bottom: 1px solid var(--external-list-border);
    font-weight: 500;
  }
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown
    li.ext-head-submenu-item
    a:link,
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown
    li.ext-head-submenu-item
    a:visited,
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown
    li.ext-head-submenu-item
    a:hover {
    color: #333333;
    color: var(--slate);
  }
  .ext-head-nav-wrapper
    ul.ext-head-nav-items
    li.faa-other:hover
    ul.ext-nav-dropdown
    li.ext-head-submenu-item:hover {
    background: #886a05;
    background: var(--external-gold);
  }
}

/* NAVIGATION CSS */

/* Common */

.devkit-left-nav-link-wrapper {
  width: 200px;
  margin: 12px 0 12px 12px;
}

.devkit-left-nav-link-wrapper .devkit-nav-heading {
  font-size: 1.25rem;
  font-weight: 600;
  background: transparent;
}

.devkit-left-nav-link-wrapper .devkit-nav-heading a:link,
.devkit-left-nav-link-wrapper .devkit-nav-heading a:hover,
.devkit-left-nav-link-wrapper .devkit-nav-heading a:visited {
  color: #005690;
  color: var(--link-active);
}

.devkit-left-nav-link-wrapper .devkit-nav-heading a:hover {
  text-decoration: underline;
}

.devkit-left-nav-link-wrapper .devkit-nav-heading.active a {
  font-weight: 600;
  color: #333333;
  color: var(--slate);
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links {
  padding-left: 0;
  padding-right: 18px;
  font-size: 1rem;
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li {
  list-style-type: none;
  padding: 10px 6px;
  font-size: 1rem;
  border-top: 1px solid #cccccc;
  border-top: 1px solid var(--light-grey);
  line-height: 1.5rem;
}

.devkit-left-nav-link-wrapper
  ul.devkit-left-nav-links
  li
  .devkit-v-inner-nav-head {
  cursor: pointer;
}

.devkit-left-nav-link-wrapper
  ul.devkit-left-nav-links
  li
  .devkit-v-inner-nav-head:after {
  content: "\25bc";
  color: #002664;
  color: var(--dark-blue);
  padding-left: 2px;
  font-size: 1rem;
  cursor: pointer;
}

.devkit-left-nav-link-wrapper
  ul.devkit-left-nav-links
  li
  ul.devkit-v-inner-nav {
  display: none;
  font-weight: 500;
}

.devkit-left-nav-link-wrapper
  ul.devkit-left-nav-links
  li
  ul.devkit-v-inner-nav
  li {
  border: none;
}

.devkit-left-nav-link-wrapper
  ul.devkit-left-nav-links
  li
  ul.devkit-v-inner-nav
  li:last-of-type {
  border: none;
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li a:link,
.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li a:visited {
  color: #005690;
  color: var(--link-active);
  text-decoration: none;
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li a:hover {
  text-decoration: underline;
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li:last-of-type {
  border-bottom: 1px solid #cccccc;
  border-bottom: 1px solid var(--light-grey);
}

.devkit-left-nav-link-wrapper ul.devkit-left-nav-links li.active a {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
  color: var(--slate);
}

@media screen and (min-width: 768px) {
  .devkit-left-nav-link-wrapper {
    width: 160px;
    margin: 6px;
  }
}

@media screen and (min-width: 960px) {
  .devkit-left-nav-link-wrapper {
    width: 200px;
    margin: 0 0 0 24px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-left-nav-link-wrapper {
    width: auto;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-left-nav-link-wrapper {
    width: 240px;
  }
}

/* Internal */

/* This file has styles for horizontal navigation bar.
For vertical navigation styles, please refer to sideNav.css */

.devkit-nav-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .devkit-nav-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .devkit-nav-wrapper {
    display: block;
    background: #e9eaed;
    background: var(--lighter-grey);
    height: 42px;
  }

  .devkit-nav-wrapper ul.devkit-nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-inner-nav-link,
  .devkit-nav-wrapper ul.devkit-nav-links li.child-menu-item {
    list-style: none;
    white-space: normal;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link {
    padding: 11px 0 7px 0;
  }
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a {
    text-decoration: none;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a:link,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a:visited,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:link,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:visited {
    color: #333333;
    color: var(--slate);
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a.devkit-main-menu-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    a.devkit-main-menu-link {
    padding: 17px 7px 11px 7px;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link:hover
    ul.submenu-nav,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    .devkit-main-menu-link:hover
    ul.submenu-nav {
    display: block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.1);
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    .devkit-main-menu-link:after {
    content: "\25bc";
    color: #002664;
    color: var(--dark-blue);
    padding-left: 2px;
    font-size: 1rem;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link ul.submenu-nav {
    display: none;
    max-width: 260px;
    width: 100%;
    position: absolute;
    background: #ffffff;
    background: var(--white);
    margin: 8px 0 6px 0;
    z-index: 30;
    padding: 10px 18px;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link {
    max-width: 256px;
    width: 100%;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.child-menu-item {
    display: inline-block;
    margin-left: 0;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    border-bottom: solid 1px #cccccc;
    border-bottom: solid 1px var(--light-grey);
    white-space: normal;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:hover,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:hover {
    padding: 10px 9px;
    background: #cccccc;
    background: var(--light-grey);
    border-radius: 4px;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link {
    display: inline-block;
    white-space: normal;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:visited,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:visited {
    padding: 10px 9px;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    ul.child-menu {
    display: none;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link.right:hover
    ul.child-menu,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:hover
    ul.child-menu {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    float: right;
    position: absolute;
    max-width: 250px;
    width: 100%;
    top: 3px;
    left: 200px;
    margin-left: 18px;
    padding: 6px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.25);
    background: #ffffff;
    background: var(--white);
  }


  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link:last-of-type,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.child-menu-item:last-of-type {
    border-bottom: 0;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side {
    min-width: 220px;
    width: 100%;
    min-height: 150px;
    z-index: 30;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side
    .devkit-inner-nav-link.right {
    max-width: 220px;
    width: 100%;
    white-space: normal;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side
    ul.child-menu.child-nav-open {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    float: right;
    position: absolute;
    max-width: 250px;
    width: 100%;
    top: 3px;
    left: 200px;
    margin-left: 18px;
    padding: 6px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.25);
    background: #ffffff;
    background: var(--white);
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side {
    max-width: 380px;
    width: 100%;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left {
    float: left;
    width: 45%;
    clear: left;
    padding-left: 6px;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left
    .left-subhead {
    padding-left: 6px;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left
    li:last-of-type {
    border-bottom: 0;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .right {
    float: right;
    width: 45%;
    clear: right;
    padding-left: 6px;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .right
    .right-subhead {
    padding-left: 6px;
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.nav-open {
    display: block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.1);
  }
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link:hover
    a.devkit-main-menu-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub:hover
    a.devkit-main-menu-link {
    background: #cccccc;
    background: var(--light-grey);
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link.active,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub.active {
    background: #ffffff;
    background: var(--white);
    color: #333333;
    color: var(--slate);
    outline: none;
    border-bottom: 5px solid #cccccc;
    border-bottom: 5px solid var(--light-grey);
    border-right: 1px solid #cccccc;
    border-right: 1px solid var(--light-grey);
    border-left: 1px solid #cccccc;
    border-left: 1px solid var(--light-grey);
  }
}

@media screen and (min-width: 1280px) {
  .devkit-nav-wrapper {
    height: 60px;
  }

  .devkit-nav-wrapper ul.devkit-nav-links {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub {
    padding: 16px 5px 16px 5px;
  }

  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link ul.submenu-nav,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    ul.submenu-nav {
    min-width: 240px;
    margin: 15px 0 6px 0;
    padding-left: 6px;
    padding-right: 12px;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    a.devkit-main-menu-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a.devkit-main-menu-link {
    padding: 21px 8px 18px 8px;
  }
}

@media screen and (min-width: 1500px) {
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link,
  .devkit-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub {
    padding: 18px 5px 16px 5px;
  }

  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    a.devkit-main-menu-link,
  .devkit-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a.devkit-main-menu-link {
    padding: 21px 8px 18px 8px;
  }
}

/* This CSS file has styles for vertical navigation on the side.
For horizontal navigation bar styles, please refer to navBar.css
This CSS file is not being used anywhere. */

.devkit-left-nav-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .devkit-left-nav-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .devkit-left-nav-wrapper {
    display: block;
    width: 250px;
    margin-top: 6px;
    margin-left: 14px;
    position: relative;
    background: #ffffff;
    background: var(--white);
    border: solid 2px #e9eaed;
    border: solid 2px var(--lighter-grey);
    border-radius: 4px;
  }

  .devkit-left-nav-wrapper .devkit-nav-heading {
    background: #e9eaed;
    background: var(--lighter-grey);
    border-bottom: solid 1px #cccccc;
    border-bottom: solid 1px var(--light-grey);
    color: #002664;
    color: var(--dark-blue);
    font-size: 1.25rem;
    font-weight: 600;
    padding: 16px;
  }

  .devkit-left-nav-wrapper li.devkit-nav-link,
  .devkit-left-nav-wrapper li.devkit-inner-nav-link {
    list-style-type: none;
  }

  .devkit-left-nav-wrapper ul.devkit-nav-links {
    display: block;
    padding-left: 12px;
    padding-right: 12px;
  }

  .devkit-left-nav-wrapper ul.devkit-nav-links li.devkit-nav-link {
    display: block;
    line-height: 2.25rem;
    border-bottom: solid 1px #e9eaed;
    border-bottom: solid 1px var(--lighter-grey);
    margin-bottom: 9px;
    font-weight: 600;
  }
  .devkit-left-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:link,
  .devkit-left-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:visited {
    color: #333333;
    color: var(--slate);
    text-decoration: none;
  }
  .devkit-left-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    span.devkit-main-menu-link:after {
    content: "\25bc";
    color: #002664;
    color: var(--dark-blue);
  }

  .devkit-left-nav-wrapper ul.devkit-nav-links li.devkit-nav-link:last-of-type {
    border-bottom: 0;
  }
  .devkit-left-nav-wrapper li.devkit-nav-link ul.devkit-nav-dropdown {
    display: none;
  }

  .devkit-left-nav-wrapper li.devkit-inner-nav-link {
    font-weight: 400;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-left-nav-wrapper {
    width: 300px;
    margin-left: 24px;
  }
}

@media screen and (min-width: 1500px) {
  & .devkit-left-nav-wrapper {
    width: 310px;
    margin-top: 6px;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-left-nav-wrapper {
    width: 330px;
    margin-top: 22px;
  }
}

/* External */

/* This file has styles for horizontal navigation bar.
For vertical navigation styles, please refer to sideNav.css */

.devkit-ext-nav-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .devkit-ext-nav-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .devkit-ext-nav-wrapper {
    display: block;
    background: #0076c0;
    background: var(--light-blue);
    height: 42px;
    margin: 0 auto 12px auto;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-inner-nav-link,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.child-menu-item {
    list-style: none;
    white-space: normal;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link {
    padding: 9px 0 10px 0;
  }
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a {
    text-decoration: none;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a:link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a:visited,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:link,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:visited {
    color: #ffffff;
    color: var(--white);
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub a:active,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link a:active {
    color: #0076c0;
    color: var(--light-blue);
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a.devkit-main-menu-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    a.devkit-main-menu-link {
    padding: 16px 7px 10px 7px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link:hover
    ul.submenu-nav,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    .devkit-main-menu-link:hover
    ul.submenu-nav {
    display: block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.1);
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub.active
    a.devkit-main-menu-link:hover {
    padding: 16px 7px 10px 7px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    .devkit-main-menu-link {
    color: #ffffff;
    color: var(--white);
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    .devkit-main-menu-link:after {
    content: "\25bc";
    color: #ffffff;
    color: var(--white);
    padding-left: 2px;
    font-size: 1rem;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav {
    display: none;
    max-width: 260px;
    position: absolute;
    background: #ffffff;
    background: var(--white);
    margin: 8px 0 6px 0;
    z-index: 30;
    padding: 10px 18px;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link {
    max-width: 256px;
    width: 100%;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.child-menu-item {
    display: inline-block;
    margin-left: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    border-bottom: solid 1px #cccccc;
    border-bottom: solid 1px var(--light-grey);
    white-space: normal;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:visited,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:visited {
    color: #333333;
    color: var(--slate);
    padding: 10px 9px;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:hover,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link:hover {
    background: #003e7e;
    background: var(--external-dark-blue);
    border-radius: 4px;
    color: #ffffff;
    color: var(--white);
    text-decoration: none;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.child-menu-item-link {
    display: inline-block;
    white-space: normal;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    ul.child-menu {
    display: none;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    span.devkit-submenu-heading {
    display: inline-block;
    margin-left: 0;
    text-align: left;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link.right:hover
    ul.child-menu,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    a.devkit-submenu-link:hover
    ul.child-menu {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    float: right;
    position: absolute;
    max-width: 250px;
    width: 100%;
    top: 3px;
    left: 200px;
    margin-left: 18px;
    padding: 6px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.25);
    background: #ffffff;
    background: var(--white);
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.devkit-inner-nav-link:last-of-type,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav
    li.child-menu-item:last-of-type {
    border-bottom: 0;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side {
    max-width: 220px;
    width: 100%;
    min-height: 150px;
    z-index: 30;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side
    .devkit-inner-nav-link.right {
    max-width: 220px;
    width: 100%;
    white-space: normal;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.child-by-side
    ul.child-menu.child-nav-open {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    float: right;
    position: absolute;
    max-width: 250px;
    width: 100%;
    top: 3px;
    left: 200px;
    margin-left: 18px;
    padding: 6px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.25);
    background: #ffffff;
    background: var(--white);
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side {
    max-width: 380px;
    width: 100%;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left {
    float: left;
    width: 45%;
    clear: left;
    padding-left: 6px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left
    .left-subhead {
    padding-left: 6px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .left
    li:last-of-type {
    border-bottom: 0;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .right {
    float: right;
    width: 45%;
    clear: right;
    padding-left: 6px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.side-by-side
    .right
    .right-subhead {
    padding-left: 6px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    ul.submenu-nav.nav-open {
    display: block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.1);
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link:hover
    a.devkit-main-menu-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub:hover
    a.devkit-main-menu-link {
    background: #15396c;
    background: var(--ext-microhead-background);
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link.active,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub.active {
    background: #003e7e;
    background: var(--external-dark-blue);
    color: #333333;
    color: var(--slate);
    outline: none;
    border-bottom: 5px solid #0076c0;
    border-bottom: 5px solid var(--light-blue);
    border-right: 1px solid #0076c0;
    border-right: 1px solid var(--light-blue);
    border-left: 1px solid #0076c0;
    border-left: 1px solid var(--light-blue);
  }
}

@media screen and (min-width: 1280px) {
  .devkit-ext-nav-wrapper {
    height: 60px;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link,
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link-nosub {
    padding: 18px 5px 16px 5px;
  }
  .devkit-ext-nav-wrapper ul.devkit-nav-links li.devkit-nav-link ul.submenu-nav,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    ul.submenu-nav {
    min-width: 240px;
    margin: 16px 0 6px 0;
    padding-left: 6px;
    padding-right: 12px;
  }

  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link
    a.devkit-main-menu-link,
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub
    a.devkit-main-menu-link {
    padding: 23px 8px 18px 8px;
  }
  .devkit-ext-nav-wrapper
    ul.devkit-nav-links
    li.devkit-nav-link-nosub.active
    a.devkit-main-menu-link:hover {
    padding: 20px 8px 18px 8px;
  }
}

/* This file has styles for horizontal navigation bar.
For vertical navigation styles, please refer to sideNav.css */

.devkit-ext-fw-nav-wrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .devkit-ext-fw-nav-wrapper {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .devkit-ext-fw-nav-wrapper {
    display: block;
    background: #0076c0;
    background: var(--light-blue);
    height: 42px;
    margin: 0 auto 12px auto;
    border-bottom: 1px solid #003e7e;
    border-bottom: 1px solid var(--external-dark-blue);
  }

  .devkit-ext-fw-nav-wrapper ul.devkit-nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 960px;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    margin: 0 auto;
  }

  .devkit-ext-fw-nav-wrapper li.devkit-nav-link {
    padding: 11px 0 12px 0;
  }
  .devkit-ext-fw-nav-wrapper li.devkit-nav-link a.devkit-main-menu-link,
  .devkit-ext-fw-nav-wrapper li.devkit-nav-link span.devkit-main-menu-link {
    padding: 16px 7px 12px 7px;
  }
  .devkit-ext-fw-nav-wrapper li.devkit-nav-link span.devkit-main-menu-link {
    color: #ffffff;
    color: var(--white);
  }
  .devkit-ext-fw-nav-wrapper li.devkit-nav-link div.devkit-nav-dropdown {
    display: none;
    min-width: 200px;
    position: absolute;
    background: #ffffff;
    background: var(--white);
    margin: 9px 0 6px 0;
    z-index: 30;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav {
    display: block;
    padding: 10px 18px;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link {
    border-bottom: solid 1px #e9eaed;
    border-bottom: solid 1px var(--lighter-grey);
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5rem;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link
    a.devkit-submenu-link:link,
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link
    a.devkit-submenu-link:visited {
    color: #333333;
    color: var(--slate);
    padding: 10px 9px;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link
    a.devkit-submenu-link:hover {
    background: #003e7e;
    background: var(--external-dark-blue);
    border-radius: 4px;
    color: #ffffff;
    color: var(--white);
    padding: 12px 9px 13px 9px;
    text-decoration: none;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link,
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    span.devkit-submenu-heading {
    display: block;
    margin-left: 0;
    text-align: left;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav
    li.devkit-inner-nav-link:last-of-type {
    border-bottom: 0;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav.left,
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav.right {
    display: inline-block;
  }

  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav.left
    span.devkit-submenu-heading,
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    div.devkit-nav-dropdown
    ul.submenu-nav.right
    span.devkit-submenu-heading {
    margin-left: 8px;
    padding: 0;
  }
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link
    span.devkit-main-menu-link:after {
    content: "\25bc";
    color: #ffffff;
    color: var(--white);
    padding-left: 2px;
    font-size: 1rem;
  }

  .devkit-ext-fw-nav-wrapper li.devkit-nav-link,
  .devkit-ext-fw-nav-wrapper li.devkit-inner-nav-link {
    list-style: none;
  }

  .devkit-ext-fw-nav-wrapper a {
    text-decoration: none;
  }

  .devkit-ext-fw-nav-wrapper a:link,
  .devkit-ext-fw-nav-wrapper a:visited {
    color: #ffffff;
    color: var(--white);
  }
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link:last-of-type
    div.devkit-nav-dropdown {
    right: 200;
  }
  .devkit-ext-fw-nav-wrapper li.devkit-nav-link:hover div.devkit-nav-dropdown {
    display: block;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.1);
  }

  .devkit-ext-fw-nav-wrapper li.devkit-nav-link:hover a.devkit-main-menu-link,
  .devkit-ext-fw-nav-wrapper
    li.devkit-nav-link:hover
    span.devkit-main-menu-link {
    background: #15396c;
    background: var(--ext-microhead-background);
  }

  .devkit-ext-fw-nav-wrapper li.devkit-nav-link.active {
    background: #ffffff;
    background: var(--white);
    color: #333333;
    color: var(--slate);
    outline: none;
    border-bottom: 5px solid #003e7e;
    border-bottom: 5px solid var(--external-dark-blue);
    border-right: 1px solid #003e7e;
    border-right: 1px solid var(--external-dark-blue);
    border-left: 1px solid #003e7e;
    border-left: 1px solid var(--external-dark-blue);
    border-radius: 6px 6px 0 0;
  }
}

/* FOOTER & FOOTER SUB COMPONENTS */

/* Internal */

.devkit-footer-wrapper {
  padding-bottom: 12px;
  background: linear-gradient(#efefef, #e6e6e6);
  background: linear-gradient(
    var(--footer-lighter-grey-background),
    var(--footer-light-grey-background)
  );
  border-radius: 5px;
  border-top: solid 1px #cccccc;
  border-top: solid 1px var(--light-grey);
  clear: both;
}

footer {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: 48px auto;
  padding-left: 12px;
}

footer .devkit-footer-logo-wrapper {
  grid-column: 1 / span 1;
  grid-row: 1;
}

footer .devkit-first-group-links {
  grid-column: 1 / span 7;
  grid-row: 2;
}

footer .devkit-second-group-links {
  grid-column: 1 / span 7;
  grid-row: 3;
}

footer .devkit-third-group-links {
  grid-column: 1 / span 7;
  grid-row: 4;
}

footer .devkit-info-group-links {
  grid-column: 1 / span 7;
  grid-row: 9 / span 1;
}

@media screen and (min-width: 768px) {
  footer {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  footer .devkit-footer-logo-wrapper {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
    padding-top: 24px;
  }

  footer .devkit-first-group-links {
    grid-column: 8 / span 7;
    grid-row: 1 / span 2;
  }

  footer .devkit-second-group-links {
    grid-column: 16 / span 7;
    grid-row: 1 / span 2;
  }

  footer .devkit-third-group-links {
    grid-column: 8 / span 7;
    grid-row: 4 / span 1;
  }

  footer .devkit-info-group-links {
    grid-column: 16 / span 7;
    grid-row: 4 / span 1;
  }
}

@media screen and (min-width: 960px) {
  .devkit-footer-wrapper {
    padding-bottom: 24px;
    background: linear-gradient(#efefef, #e6e6e6);
    background: linear-gradient(
      var(--footer-lighter-grey-background),
      var(--footer-light-grey-background)
    );
    border-radius: 5px;
    border-top: solid 1px #cccccc;
    border-top: solid 1px var(--light-grey);
    clear: both;
  }
  footer {
    grid-template-rows: auto;
  }

  footer .devkit-footer-logo-wrapper {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
  }

  footer .devkit-first-group-links {
    grid-column: 7 / span 7;
    grid-row: 1 / span 2;
  }

  footer .devkit-second-group-links {
    grid-column: 15 / span 8;
    grid-row: 1 / span 2;
  }

  footer .devkit-third-group-links {
    grid-column: 7 / span 7;
    grid-row: 4 / span 1;
  }

  footer .devkit-info-group-links {
    grid-column: 15 / span 8;
    grid-row: 4 / span 1;
  }
}

@media screen and (min-width: 1280px) {
  footer {
    grid-template-rows: 36px auto;
  }

  footer .devkit-footer-logo-wrapper {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
  }

  footer .devkit-first-group-links {
    grid-column: 5 / span 4;
    grid-row: 1 / span 2;
  }

  footer .devkit-second-group-links {
    grid-column: 10 / span 5;
    grid-row: 1 / span 2;
  }

  footer .devkit-third-group-links {
    grid-column: 16 / span 4;
    grid-row: 1 / span 2;
  }

  footer .devkit-info-group-links {
    grid-column: 21 / span 3;
    grid-row: 1 / span 2;
  }
}

.devkit-footer-logo-wrapper {
  padding-left: 6px;
  font-size: 1.5rem;
  font-weight: 400;
}

.devkit-footer-logo-wrapper a:link,
.devkit-footer-logo-wrapper a:visited,
.devkit-footer-logo-wrapper a:hover {
  color: #0076c0;
  color: var(--light-blue);
  text-decoration: none;
}

.devkit-footer-logo-wrapper .devkit-footer-special {
  color: #002664;
  color: var(--dark-blue);
}

@media screen and (min-width: 768px) {
  .devkit-footer-logo-wrapper {
    padding-left: 24px;
    font-size: 3rem;
    font-weight: 600;
  }
}

.devkit-footer-groups .devkit-footer-group-title,
.devkit-info-group-links .devkit-footer-group-title {
  margin-left: 6px;
  margin-top: 12px;
  font-size: 0.875rem;
  font-weight: 400;
}

.devkit-footer-groups ul.devkit-footer-links,
.devkit-info-group-links ul.devkit-footer-links {
  display: block;
  padding-left: 6px;
  margin: 0;
}

.devkit-footer-groups ul.devkit-footer-links li,
.devkit-info-group-links ul.devkit-footer-links li {
  display: inline;
  font-weight: 300;
  list-style-type: none;
  line-height: 0.375rem;
  font-size: 0.875rem;
  margin-right: 9px;
}

@media screen and (min-width: 768px) {
  .devkit-footer-groups .devkit-footer-group-title,
  .devkit-info-group-links .devkit-footer-group-title {
    margin-left: 0;
    margin-bottom: 12px;
    padding-top: 24px;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .devkit-footer-groups ul.devkit-footer-links,
  .devkit-info-group-links ul.devkit-footer-links {
    display: block;
    padding-left: 0;
  }

  .devkit-footer-groups ul.devkit-footer-links li:last-of-type,
  .devkit-info-group-links ul.devkit-footer-links li:last-of-type {
    margin-bottom: 8px;
  }

  .devkit-footer-groups ul.devkit-footer-links li,
  .devkit-info-group-links ul.devkit-footer-links li {
    display: block;
    margin-bottom: 16px;
    font-size: 1rem;
  }
}

/* External */

/* Start of No Blue Footer CSS */

.devkit-ext-no-footer-wrapper {
  display: table;
  width: 100%;
  border-top: 3px solid #937206;
  border-top: 3px solid var(--ext-footer-top-border);
  border-bottom: 6px solid #003e7e;
  border-bottom: 6px solid var(--primary-blue);
}

/*End of No Blue Footer CSS */

.devkit-ext-footer-wrapper {
  display: block;
  width: 100%;
  background: #003e7e;
  background: var(--primary-blue);
  color: #ffffff;
  color: var(--white);
  border-top: 3px solid #937206;
  border-top: 3px solid var(--ext-footer-top-border);
  border-bottom: 2px solid #00264d;
  border-bottom: 2px solid var(--ext-footer-bottom-border);
}

.devkit-ext-footer-wrapper .ext-footer,
.devkit-ext-footer-wrapper .ext-fw-footer {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto 12px auto;
  width: auto;
  margin: 0 auto;
}

.devkit-ext-footer-wrapper .ext-footer .devkit-ext-flinks-wrapper,
.devkit-ext-footer-wrapper .ext-fw-footer .devkit-ext-flinks-wrapper {
  grid-column: 1 / span 7;
  grid-row: 1;
}

.devkit-ext-footer-wrapper .ext-footer .devkit-fsocial-wrapper,
.devkit-ext-footer-wrapper .ext-fw-footer .devkit-fsocial-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
}

@media screen and (min-width: 960px) {
  .devkit-ext-footer-wrapper .ext-footer {
    width: 100%;
  }
  .devkit-ext-footer-wrapper
    .ext-footer
    .devkit-ext-flinks-wrapper
    .devkit-flinks-col:first-of-type {
    margin-left: 24px;
  }

  .devkit-ext-footer-wrapper
    .ext-footer
    .devkit-ext-flinks-wrapper
    .devkit-flinks-col:last-of-type {
    margin-right: 48px;
  }
  .devkit-ext-footer-wrapper .ext-fw-footer {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 1%;
  }
  .devkit-ext-footer-wrapper .ext-footer,
  .devkit-ext-footer-wrapper .ext-fw-footer {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto 12px auto;
  }

  .devkit-ext-footer-wrapper .ext-footer .devkit-ext-flinks-wrapper,
  .devkit-ext-footer-wrapper .ext-fw-footer .devkit-ext-flinks-wrapper {
    grid-column: 1 / span 23;
    grid-row: 1;
  }

  .devkit-ext-footer-wrapper .ext-footer .devkit-fsocial-wrapper,
  .devkit-ext-footer-wrapper .ext-fw-footer .devkit-fsocial-wrapper {
    grid-column: 1 / span 23;
    grid-row: 3;
  }
}

.devkit-fsocial-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  color: var(--white);
}

.devkit-fsocial-wrapper ul {
  list-style-type: none;
  padding-left: 0;
}

.devkit-fsocial-wrapper ul li {
  display: inline-block;
  margin-right: 16px;
}

.devkit-fsocial-wrapper ul li img {
  border-radius: 6px;
}

.devkit-ext-flinks-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.devkit-ext-flinks-wrapper .devkit-flinks-col:not(:last-child) {
  padding-right: 16px;
}

.devkit-ext-flinks-wrapper .devkit-flinks-col h2,
.devkit-ext-flinks-wrapper .devkit-flinks-col .devkit-h2 {
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.devkit-ext-flinks-wrapper .devkit-flinks-col ul {
  list-style-type: none;
  padding-left: 0;
}

.devkit-ext-flinks-wrapper .devkit-flinks-col ul li {
  display: block;
  margin-top: 10px;
  font-size: 0.9375rem;
}

.devkit-ext-flinks-wrapper .devkit-flinks-col ul li a:link,
.devkit-ext-flinks-wrapper .devkit-flinks-col ul li a:hover,
.devkit-ext-flinks-wrapper .devkit-flinks-col ul li a:visited {
  color: #ffffff;
  color: var(--white);
}

.devkit-ext-flinks-wrapper .devkit-flinks-col ul li a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 960px) {
  .devkit-ext-flinks-wrapper {
    flex-wrap: nowrap;
  }
}

/* INPUT FORMS CSS */

/* Common */

.devkit-checkradio-wrapper {
  display: flex;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
}

.devkit-checkradio-wrapper fieldset.devkit-checkradio-fieldset {
  padding: 12px;
  min-width: 300px;
  font-size: 0.875rem;
}

.devkit-checkradio-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
  margin: 12px 0;
  padding: 0;
}

.devkit-checkradio-wrapper fieldset ul.devkit-sample-checkbox,
.devkit-checkradio-wrapper fieldset ul.devkit-sample-radio-button {
  padding-left: 0;
  list-style-type: none;
}

.devkit-checkradio-wrapper fieldset ul.devkit-sample-checkbox li,
.devkit-checkradio-wrapper fieldset ul.devkit-sample-radio-button li {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.devkit-group-multi-select-form-wrapper {
  display: flex;
  justify-content: flex-start;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
  font-size: 0.875rem;
}

.devkit-group-multi-select-form-wrapper
  fieldset.devkit-group-select-fieldset-wrapper {
  padding: 12px;
  min-width: 280px;
}

.devkit-group-multi-select-form-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  margin: 12px 0;
  padding: 0;
}

.devkit-group-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-dropdown-label {
  display: block;
  font-weight: 600;
}

.devkit-group-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-group-select-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  margin-top: 12px;
  padding: 6px;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
}

.devkit-group-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-group-select-dropdown
  .devkit-dropdown-label {
  font-weight: 500;
  text-align: left;
}

.devkit-group-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-group-select-dropdown
  .fa-caret-down {
  font-size: 1.25rem;
  color: #353535;
  color: var(--charcoal);
}

.devkit-group-multi-select-form-wrapper .devkit-group-select-options-wrapper {
  display: none;
  width: 232px;
  margin-left: inherit;
  padding-top: 24px;
  border-right: solid 1px #cccccc;
  border-right: solid 1px var(--light-grey);
  border-left: solid 1px #cccccc;
  border-left: solid 1px var(--light-grey);
  border-bottom: solid 1px #cccccc;
  border-bottom: solid 1px var(--light-grey);
}

.devkit-group-multi-select-form-wrapper
  .devkit-group-select-options-wrapper
  .devkit-group-checkbox-wrapper
  label {
  font-weight: 600;
  cursor: pointer;
}

.devkit-group-multi-select-form-wrapper
  .devkit-group-select-options-wrapper
  .devkit-group-checkbox-wrapper
  [type="checkbox"]
  + label {
  padding-left: 6px;
}

.devkit-group-multi-select-form-wrapper
  .devkit-group-select-options-wrapper
  .devkit-group-children {
  cursor: pointer;
  margin-left: 24px;
  padding-left: 6px;
}

.devkit-group-multi-select-form-wrapper
  .devkit-group-select-options-wrapper
  .devkit-group-checkbox-wrapper,
.devkit-group-multi-select-form-wrapper
  .devkit-group-select-options-wrapper
  .devkit-checkbox-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

@media screen and (min-width: 768px) {
  .devkit-group-multi-select-form-wrapper
    fieldset.devkit-group-select-fieldset-wrapper {
    min-width: 400px;
    padding: 24px;
    font-size: 1rem;
  }

  .devkit-group-multi-select-form-wrapper .devkit-label-wrapper {
    display: flex;
    align-items: center;
  }

  .devkit-group-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-dropdown-label,
  .devkit-group-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-multi-select-dropdown {
    display: inline-block;
  }

  .devkit-group-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-dropdown-label {
    width: 160px;
    text-align: right;
  }

  .devkit-group-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-group-select-dropdown {
    width: 200px;
    margin-left: 24px;
  }

  .devkit-group-multi-select-form-wrapper .devkit-group-select-options-wrapper {
    width: 212px;
    margin-left: 184px; /* This margin will change based on the dropdown placement. */
  }
}

.devkit-multi-select-form-wrapper {
  display: flex;
  justify-content: flex-start;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
  font-size: 0.875rem;
}

.devkit-multi-select-form-wrapper
  fieldset.devkit-multi-select-fieldset-wrapper {
  padding: 12px;
  min-width: 280px;
}

.devkit-multi-select-form-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
  margin: 12px 0;
  padding: 0;
}

.devkit-multi-select-form-wrapper .devkit-label-wrapper .devkit-dropdown-label {
  display: block;
  font-weight: 600;
}

.devkit-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-multi-select-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-top: 12px;
  padding: 6px;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
}

.devkit-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-multi-select-dropdown
  .devkit-dropdown-label {
  font-weight: 500;
  text-align: left;
}

.devkit-multi-select-form-wrapper
  .devkit-label-wrapper
  .devkit-multi-select-dropdown
  .fa-caret-down {
  font-size: 1.25rem;
  color: #353535;
  color: var(--charcoal);
}

.devkit-multi-select-form-wrapper .devkit-multi-select-options-wrapper {
  display: none;
  width: 212px;
  margin-left: inherit;
  padding-top: 24px;
  border-right: solid 1px #cccccc;
  border-right: solid 1px var(--light-grey);
  border-left: solid 1px #cccccc;
  border-left: solid 1px var(--light-grey);
  border-bottom: solid 1px #cccccc;
  border-bottom: solid 1px var(--light-grey);
}

.devkit-multi-select-form-wrapper .devkit-multi-checkbox-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.devkit-multi-select-form-wrapper
  .devkit-multi-checkbox-wrapper
  [type="checkbox"]
  + label {
  padding-left: 6px;
}

.devkit-multi-select-form-wrapper .devkit-pills-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  max-width: 360px;
  margin-left: 24px;
}

.devkit-multi-select-form-wrapper .devkit-pills-wrapper .devkit-pill {
  display: none;
}

.devkit-multi-select-form-wrapper
  .devkit-pills-wrapper
  .devkit-pill
  .devkit-pill-wrapper {
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 6px 9px 2px 9px;
  font-size: 0.875rem;
  font-weight: 500;
  background: #0076c0;
  background: var(--light-blue);
  color: #ffffff;
  color: var(--white);
  border-radius: 9px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .devkit-multi-select-form-wrapper
    fieldset.devkit-multi-select-fieldset-wrapper {
    min-width: 300px;
    padding: 24px;
    font-size: 1rem;
  }

  .devkit-multi-select-form-wrapper .devkit-label-wrapper {
    display: flex;
    align-items: center;
  }

  .devkit-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-dropdown-label {
    width: 150px;
    text-align: right;
  }

  .devkit-multi-select-form-wrapper
    .devkit-label-wrapper
    .devkit-multi-select-dropdown {
    margin-left: 24px;
  }

  .devkit-multi-select-form-wrapper .devkit-multi-select-options-wrapper {
    width: 212px;
    margin-left: 174px;
  }
}

.devkit-single-select-dropdown-wrapper {
  display: flex;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
  font-size: 1rem;
}

.devkit-single-select-dropdown-wrapper
  fieldset.devkit-single-select-fieldset-wrapper {
  padding: 12px;
  min-width: 300px;
}

.devkit-single-select-dropdown-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
  margin: 12px 0;
  padding: 0;
}

.devkit-single-select-dropdown-wrapper fieldset .devkit-form-row {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 30px;
  margin-bottom: 12px;
  padding: 9px 3px;
}

.devkit-single-select-dropdown-wrapper fieldset .devkit-form-row label {
  font-size: 1.125rem;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row
  select::-ms-expand {
  /* This is to hide default dropdown arrow in IE */
  display: none;
}

.devkit-single-select-dropdown-wrapper fieldset .devkit-form-row select {
  width: 320px;
  padding: 9px 48px 9px 9px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  color: #333333;
  color: var(--slate);
  font-size: 1rem;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row
  select
  optgroup.devkit-optgroup {
  margin-top: 6px;
  font-size: 1rem;
}

.devkit-single-select-dropdown-wrapper fieldset .devkit-form-row select option {
  padding: 6px;
  font-size: 1rem;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row
  select
  option:hover {
  background: #0e76bd;
  background: var(--hover-blue);
  color: #ffffff;
  color: var(--white);
  padding: 10px 6px 0 6px;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row
  select:disabled {
  width: 320px;
  background: #d0d0d0;
  background: var(--background-grey);
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row:nth-of-type(2n + 1):after,
.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row:nth-of-type(2n):after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free", sans-serif;
  color: #353535;
  color: var(--charcoal);
  top: 37px;
  height: 34px;
  padding: 18px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
  visibility: visible;
  font-size: 1.25rem;
  font-weight: 900;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row:nth-of-type(2n + 1):after {
  right: 54px;
}

.devkit-single-select-dropdown-wrapper
  fieldset
  .devkit-form-row:nth-of-type(2n):after {
  right: 60px;
}

.devkit-single-select-dropdown-wrapper .devkit-required {
  font-size: 1.625rem;
  color: #c32026;
  color: var(--red);
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  .devkit-single-select-dropdown-wrapper
    fieldset.devkit-select-fieldset-wrapper {
    min-width: 400px;
    padding: 24px;
    font-size: 1rem;
  }
  .devkit-single-select-dropdown-wrapper fieldset .devkit-form-row {
    margin-bottom: 12px;
    padding: 12px 6px;
  }

  .devkit-single-select-dropdown-wrapper
    fieldset
    .devkit-form-row
    .devkit-form-row:nth-of-type(2n + 1):after,
  .devkit-single-select-dropdown-wrapper
    fieldset
    .devkit-form-row
    .devkit-form-row:nth-of-type(2n):after {
    top: 40px;
  }

  .devkit-single-select-dropdown-wrapper fieldset .devkit-form-row select {
    width: 320px;
  }
}

.devkit-single-select-list-wrapper {
  display: flex;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
  font-size: 1rem;
}

.devkit-single-select-list-wrapper
  fieldset.devkit-single-select-fieldset-wrapper {
  padding: 12px;
}

.devkit-single-select-list-wrapper fieldset {
  margin: 12px 0;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
}

.devkit-single-select-list-wrapper fieldset .devkit-form-row {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 12px;
  padding: 9px 3px;
  font-size: 1.125rem;
}

.devkit-single-select-list-wrapper
  fieldset
  .devkit-form-row
  optgroup.devkit-optgroup {
  color: #333333;
  color: var(--slate);
  margin-top: 6px;
  font-size: 1rem;
}

.devkit-single-select-list-wrapper fieldset .devkit-form-row option {
  padding: 6px;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
}

.devkit-single-select-list-wrapper fieldset .devkit-form-row option:hover {
  background: #0e76bd;
  background: var(--hover-blue);
  color: #ffffff;
  color: var(--white);
  padding: 8px 6px 4px 6px;
}

.devkit-single-select-list-wrapper fieldset .devkit-form-row select:disabled {
  background: #d0d0d0;
  background: var(--background-grey);
}

.devkit-single-select-list-wrapper .devkit-required {
  font-size: 1.625rem;
  color: #c32026;
  color: var(--red);
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  .devkit-single-select-list-wrapper fieldset.devkit-select-fieldset-wrapper {
    padding: 24px;
    font-size: 1rem;
  }
  .devkit-single-select-list-wrapper fieldset .devkit-form-row select {
    width: 350px;
  }
}

.devkit-slider-wrapper {
  width: 280px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #0076c0;
  background: var(--light-blue);
  border: solid 1px #003e7e;
  border: solid 1px var(--primary-blue);
  outline: none;
  cursor: pointer;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -7px;
  margin-left: -10px;
}

/* The switch - the box around the slider */

.devkit-toggle-switch-wrapper {
  margin-top: 36px;
}

.devkit-toggle-switch-wrapper .devkit-toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.devkit-toggle-switch-wrapper .devkit-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.devkit-toggle-switch-wrapper .devkit-toggle-switch .devkit-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e6e6e6;
  background: var(--grey);
  transition: 0.4s;
}

.devkit-toggle-switch-wrapper .devkit-toggle-switch .devkit-toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: #ffffff;
  background: var(--white);
  transition: 0.4s;
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  input:checked
  + .devkit-toggle {
  background: #0076c0;
  background: var(--light-blue);
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  input:focus
  + .devkit-toggle {
  box-shadow: 0 0 1px #003e7e;
  box-shadow: 0 0 1px var(--primary-blue);
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  input
  + .devkit-toggle:after {
  display: block;
  content: "Disabled";
  margin-top: 42px;
  margin-left: 2px;
  font-weight: 600;
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  input:checked
  + .devkit-toggle:before {
  transform: translateX(26px);
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  input:checked
  + .devkit-toggle:after {
  display: block;
  content: "Enabled";
  margin-top: 42px;
  margin-left: 2px;
  font-weight: 600;
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  .devkit-toggle.devkit-round {
  border-radius: 34px;
}

.devkit-toggle-switch-wrapper
  .devkit-toggle-switch
  .devkit-toggle.devkit-round:before {
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .devkit-slider-wrapper {
    width: 500px;
  }
}

.devkit-textarea-input-form-wrapper {
  display: flex;
  margin-left: 0;
  color: #333333;
  color: var(--slate);
}

.devkit-textarea-input-form-wrapper
  fieldset.devkit-textarea-input-fieldset-wrapper {
  min-width: 300px;
  padding: 6px;
  font-size: 1rem;
}

.devkit-textarea-input-form-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
  margin: 12px 0;
  padding: 0;
}

.devkit-textarea-input-form-wrapper fieldset textarea {
  padding-left: 6px;
}

.devkit-textarea-input-form-wrapper fieldset .devkit-form-row {
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
}

.devkit-textarea-input-form-wrapper fieldset .devkit-form-row label {
  font-size: 1.125rem;
}

.devkit-textarea-input-form-wrapper fieldset .devkit-form-row textarea {
  width: 300px;
  border: solid 1px #797d7f;
  border: solid 1px var(--darker-grey);
}

.devkit-textarea-input-form-wrapper
  fieldset
  .devkit-form-row
  .devkit-character-count {
  align-self: flex-end;
}

@media screen and (min-width: 768px) {
  .devkit-textarea-input-form-wrapper
    fieldset.devkit-textarea-input-fieldset-wrapper {
    padding: 24px;
    font-size: 1rem;
  }
  .devkit-textarea-input-form-wrapper fieldset .devkit-form-row textarea {
    width: 420px;
  }
}

.devkit-text-input-form-wrapper {
  display: flex;
  color: #333333;
  color: var(--slate);
  margin-left: 0;
}

.devkit-text-input-form-wrapper fieldset.devkit-text-input-fieldset-wrapper {
  padding: 6px;
}

.devkit-text-input-form-wrapper fieldset {
  border: 1px solid transparent;
  border-radius: 3px;
  clear: both;
  margin: 12px 0;
  padding: 0;
}

.devkit-text-input-form-wrapper fieldset .devkit-required {
  font-size: 1.625rem;
  color: #c32026;
  color: var(--red);
  vertical-align: bottom;
}

.devkit-text-input-form-wrapper fieldset .devkit-input-error {
  max-width: 300px;
  border-left: 5px solid #c32026;
  border-left: 5px solid var(--red);
  margin-top: 30px;
  margin-bottom: 36px;
  padding-left: 18px;
  padding-top: 6px;
  position: relative;
  right: 22px;
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-input-error
  .devkit-input-error-message {
  color: #c32026;
  color: var(--red);
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 3px;
  padding-top: 3px;
}

.devkit-text-input-form-wrapper fieldset .devkit-input-error label {
  font-size: 1.125rem;
  font-weight: 600;
}

.devkit-text-input-form-wrapper fieldset .devkit-input-error input {
  width: 200px;
  height: 20px;
  margin: 3px 0;
  padding: 12px 6px;
  font-size: 1rem;
  border: 3px solid #c32026;
  border: 3px solid var(--red);
}

.devkit-text-input-form-wrapper fieldset .devkit-form-row {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.devkit-text-input-form-wrapper fieldset .devkit-form-row label {
  font-size: 1.125rem;
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-form-row
  .devkit-input-success {
  border: 3px solid #22763b;
  border: 3px solid var(--green);
}

.devkit-text-input-form-wrapper fieldset .devkit-form-row input,
.devkit-text-input-form-wrapper fieldset .devkit-form-row input[type="file"] {
  width: 200px;
  height: 20px;
  margin: 3px 0;
  padding: 12px 6px;
  border: solid 1px #797d7f;
  border: solid 1px var(--darker-grey);
  font-size: 1rem;
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-form-row
  input::-moz-placeholder {
  font-size: 1rem;
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-form-row
  input:-ms-input-placeholder {
  font-size: 1rem;
}

.devkit-text-input-form-wrapper fieldset .devkit-form-row input::placeholder {
  font-size: 1rem;
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-form-row
  .form-button-wrapper
  .primary.default {
  margin-right: 24px;
  margin-left: 0;
  padding: 7px 15px 6px 15px;
  font-size: 0.875rem;
  color: #ffffff;
  color: var(--white);
}

.devkit-text-input-form-wrapper
  fieldset
  .devkit-form-row
  .form-button-wrapper
  .secondary.default {
  padding: 7px 15px 6px 15px;
  font-size: 0.875rem;
  color: #ffffff;
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .devkit-text-input-form-wrapper fieldset.devkit-text-input-fieldset-wrapper {
    padding: 24px;
    font-size: 1rem;
  }
  .devkit-text-input-form-wrapper fieldset .devkit-form-row input,
  .devkit-text-input-form-wrapper fieldset .devkit-form-row input[type="file"] {
    min-width: 420px;
  }

  .devkit-text-input-form-wrapper fieldset .devkit-input-error {
    right: 30px;
  }
  .devkit-text-input-form-wrapper fieldset .devkit-input-error input,
  .devkit-text-input-form-wrapper
    fieldset
    .devkit-input-error
    input[type="file"] {
    min-width: 426px;
  }
}

/* LISTS CSS */

/* Common */

.devkit-ordered-list-wrapper {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
  color: var(--slate);
}

.devkit-ordered-list-wrapper .mobile-head {
  display: block;
}

.devkit-ordered-list-wrapper .devkit-ordered-list-label {
  font-weight: 600;
}

.devkit-ordered-list-wrapper ol.devkit-outer {
  list-style-type: decimal;
}

.devkit-ordered-list-wrapper ol.devkit-mid {
  list-style-type: lower-alpha;
}

.devkit-ordered-list-wrapper ol.devkit-inner {
  list-style-type: lower-roman;
}

@media screen and (min-width: 1280px) {
  .devkit-ordered-list-wrapper .mobile-head {
    display: none;
  }
}

.devkit-unordered-list-wrapper {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
  color: var(--slate);
}

.devkit-unordered-list-wrapper .devkit-unordered-list-label {
  font-weight: 600;
}

/* TABLES CSS */

/* Common */

.devkit-merge-cells-table-wrapper {
  margin-bottom: 24px;
}

.devkit-merge-cells-table-wrapper .devkit-table {
  width: 98%;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-collapse: collapse;
  border-spacing: 0;
}

.devkit-merge-cells-table-wrapper .devkit-table .devkit-table-title {
  padding: 10px 9px 8px 16px;
  background: #0076c0;
  background: var(--light-blue);
  color: #ffffff;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

.devkit-merge-cells-table-wrapper .devkit-table thead th,
.devkit-merge-cells-table-wrapper .devkit-table tr th {
  min-width: 100px;
  padding: 4px;
  text-align: left;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  font-weight: 600;
  color: #002664;
  color: var(--dark-blue);
}

.devkit-merge-cells-table-wrapper .devkit-table tbody {
  font-size: 0.875rem;
}

.devkit-merge-cells-table-wrapper .devkit-table tbody td {
  min-width: 100px;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  padding: 10px;
}

.devkit-merge-cells-table-wrapper tr:hover {
  font-weight: 600;
}

.devkit-merge-cells-table-wrapper tr.devkit-table-row:nth-child(even) {
  background: #f4f6f9;
  background: var(--table-grey);
}

.devkit-merge-cells-table-wrapper tr.devkit-table-row:nth-child(odd) {
  background: #ffffff;
  background: var(--white);
}

@media screen and (min-width: 768px) {
  .devkit-merge-cells-table-wrapper .devkit-table .devkit-table-title {
    padding: 10px 9px 8px 16px;
    font-size: 1rem;
    font-weight: 600;
  }
  .devkit-merge-cells-table-wrapper .devkit-table thead th {
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .devkit-merge-cells-table-wrapper .devkit-table {
    width: 100%;
    border: 1px solid #cccccc;
    border: 1px solid var(--light-grey);
    border-bottom: 0;
  }

  .devkit-merge-cells-table-wrapper .devkit-table .devkit-table-title {
    padding: 10px 9px 8px 16px;
    background: #0076c0;
    background: var(--light-blue);
    color: #ffffff;
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
  }

  .devkit-merge-cells-table-wrapper .devkit-table thead {
    display: none;
    border: 0;
  }

  .devkit-merge-cells-table-wrapper .devkit-table tr {
    display: block;
    border-bottom: 1px solid #cccccc;
    border-bottom: 1px solid var(--light-grey);
  }
  .devkit-merge-cells-table-wrapper .devkit-table tr th {
    border: 0;
  }
  .devkit-merge-cells-table-wrapper .devkit-table tbody td {
    display: block;
    border: 0;
    font-size: 0.875rem;
    text-align: right;
  }

  .devkit-merge-cells-table-wrapper .devkit-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
    color: #002664;
    color: var(--dark-blue);
  }

  .devkit-merge-cells-table-wrapper .devkit-table td:last-child {
    border-bottom: none;
  }
}

.devkit-simple-table-wrapper {
  margin-bottom: 24px;
}

.devkit-simple-table-wrapper .devkit-table {
  width: 98%;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  color: #333333;
  color: var(--slate);
}

.devkit-simple-table-wrapper .devkit-table .devkit-table-title {
  padding: 8px 9px 6px 16px;
  background: #0076c0;
  background: var(--light-blue);
  color: #ffffff;
  color: var(--white);
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

.devkit-simple-table-wrapper .devkit-table thead th {
  padding: 10px;
  text-align: left;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-left: none;
  border-right: none;
  font-weight: 600;
  color: #002664;
  color: var(--dark-blue);
}

.devkit-simple-table-wrapper .devkit-table tbody {
  font-size: 0.875rem;
}

.devkit-simple-table-wrapper .devkit-table tbody td {
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  padding: 10px;
  border-left: none;
  border-right: none;
}

.devkit-simple-table-wrapper .devkit-table tr .fa {
  padding-right: 9px;
  cursor: pointer;
}

.devkit-simple-table-wrapper .devkit-table tr:hover {
  font-weight: 600;
}

.devkit-simple-table-wrapper .devkit-table tr.devkit-table-row:nth-child(even) {
  background: #f4f6f9;
  background: var(--table-grey);
}

.devkit-simple-table-wrapper .devkit-table tr.devkit-table-row:nth-child(odd) {
  background: #ffffff;
  background: var(--white);
}

@media screen and (max-width: 600px) {
  .devkit-simple-table-wrapper .devkit-table {
    border: 1px solid #cccccc;
    border: 1px solid var(--light-grey);
    border-bottom: 0;
  }

  .devkit-simple-table-wrapper .devkit-table .devkit-table-title {
    padding: 8px 9px 6px 16px;
    background: #0076c0;
    background: var(--light-blue);
    color: #ffffff;
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
  }

  .devkit-simple-table-wrapper .devkit-table thead {
    display: none;
  }

  .devkit-simple-table-wrapper .devkit-table tr {
    border-bottom: 1px solid #cccccc;
    border-bottom: 1px solid var(--light-grey);
    display: block;
    margin-bottom: 0;
  }
  .devkit-simple-table-wrapper .devkit-table tbody td {
    display: block;
    border: 0;
    font-size: 0.875rem;
    text-align: right;
  }

  .devkit-simple-table-wrapper .devkit-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
    color: #002664;
    color: var(--dark-blue);
  }

  .devkit-simple-table-wrapper .devkit-table td:last-child {
    border-bottom: none;
  }
}

/* TABS CSS */

.devkit-tab-wrapper {
  width: 330px;
  z-index: 20;
  margin-bottom: 24px;
}

.devkit-tab-wrapper h3,
.devkit-tab-wrapper .devkit-h3 {
  margin-top: 0;
  margin-left: 6px;
}

.devkit-tab-wrapper ul.devkit-tabs {
  margin: 0;
  padding-left: 0;
}

.devkit-tab-wrapper ul.devkit-tabs li {
  display: inline;
  border-bottom-width: 0;
  margin: 0 1px 0 0;
  padding: 11px 14px 11px 14px;
  list-style: none;
  background: #e6e6e6;
  background: var(--tab-background);
  text-decoration: none;
  color: #005690;
  color: var(--link-active);
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.devkit-tab-wrapper ul.devkit-tabs li.active {
  background: #ffffff;
  background: var(--white);
  color: #333333;
  color: var(--slate);
  font-weight: 700;
  position: relative;
  top: 1px;
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid var(--white);
  outline: none;
}

.devkit-tab-wrapper ul.devkit-tabs li:hover {
  text-decoration: underline;
}

.devkit-tab-wrapper .devkit-tab-content {
  display: none;
  margin-top: 6px;
  padding: 15px;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
}

.devkit-tab-wrapper .devkit-tab-content.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .devkit-tab-wrapper {
    width: 550px;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-tab-wrapper {
    margin-right: 24px;
  }
}

.devkit-vertical-tab-wrapper {
  width: 330px;
  z-index: 21;
  padding-left: 6px;
}

.devkit-vertical-tab-wrapper h3,
.devkit-vertical-tab-wrapper .devkit-h3 {
  margin-top: 0;
}

.devkit-vertical-tab-wrapper .devkit-vertical-tabbed-area {
  display: flex;
  align-items: flex-start;
}

.devkit-vertical-tab-wrapper ul.devkit-vertical-tabs {
  margin: 0;
  padding-top: 6px;
  padding-left: 0;
}

.devkit-vertical-tab-wrapper ul.devkit-vertical-tabs li {
  display: block;
  width: 80px;
  border-right-width: 0;
  list-style: none;
  margin: 0 0 1px 0;
  padding: 10px 6px 6px 6px;
  background: #e6e6e6;
  background: var(--tab-background);
  text-decoration: none;
  color: #005690;
  color: var(--link-active);
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.devkit-vertical-tab-wrapper ul.devkit-vertical-tabs li.active {
  background: #ffffff;
  background: var(--white);
  color: #333333;
  color: var(--slate);
  font-weight: 700;
  position: relative;
  right: -1px; /* we need this negative position for active vertical tab border. */
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
  border-right: 0;
  outline: none;
}

.devkit-vertical-tab-wrapper ul.devkit-vertical-tabs li:hover {
  text-decoration: underline;
}

.devkit-vertical-tab-wrapper .devkit-vertical-content {
  display: none;
  margin-top: 6px;
  min-height: 200px;
  padding: 15px;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
}

.devkit-vertical-tab-wrapper .devkit-vertical-content.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .devkit-vertical-tab-wrapper {
    width: 520px;
  }
}

@media screen and (min-width: 1500px) {
  .devkit-vertical-tab-wrapper {
    padding-left: 0;
  }
}

/* DIALOGS/ALERTS CSS */

/* Common */

.devkit-error-message-wrapper {
  display: flex;
  width: auto;
  margin: 0 24px 12px 12px;
  font-size: 1rem;
  background: #f9dede;
  background: var(--tutu);
  border-left: 11px solid #c32026;
  border-left: 11px solid var(--red);
}

.devkit-error-message-wrapper .devkit-message-heading-wrapper .fa-times-circle {
  padding: 24px 3px 3px 20px;
  font-size: 2.25rem;
}

.devkit-error-message-wrapper .devkit-message-body-wrapper {
  width: 100%;
  font-weight: 400;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-error-message-wrapper .devkit-message-body-wrapper .fa-times {
  float: right;
  margin-top: 0;
  padding: 9px 12px 9px 9px;
  color: #333333;
  color: var(--slate);
  font-size: 1rem;
}

.devkit-error-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-heading {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 24px 24px 0 14px;
}

.devkit-error-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-body {
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 0 24px 24px 14px;
}

@media screen and (min-width: 768px) {
  .devkit-error-message-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-error-message-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

.devkit-info-message-wrapper {
  display: flex;
  width: auto;
  margin: 0 24px 12px 12px;
  font-size: 1rem;
  background: #e1f3f8;
  background: var(--light-cyan);
  border-left: 11px solid #02bfe7;
  border-left: 11px solid var(--sky-blue);
}

.devkit-info-message-wrapper .devkit-message-heading-wrapper .fa-info-circle {
  padding: 24px 3px 3px 20px;
  font-size: 2.25rem;
}

.devkit-info-message-wrapper .devkit-message-body-wrapper {
  width: 100%;
  font-weight: 400;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-info-message-wrapper .devkit-message-body-wrapper .fa-times {
  float: right;
  margin-top: 0;
  padding: 9px 12px 9px 9px;
  color: #333333;
  color: var(--slate);
  font-size: 1rem;
}

.devkit-info-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-heading {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 24px 24px 0 14px;
}

.devkit-info-message-wrapper .devkit-message-body-wrapper .devkit-message-body {
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 0 24px 24px 14px;
}

@media screen and (min-width: 768px) {
  .devkit-info-message-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-info-message-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

.devkit-loading-message-wrapper {
  display: block;
  width: 250px;
  margin: 0 24px 12px 12px;
  font-size: 1rem;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
}

.devkit-loading-message-wrapper .fa-times {
  float: right;
  padding: 9px;
  color: #333333;
  color: var(--slate);
  font-size: 0.75rem;
}

.devkit-loading-message-wrapper .devkit-message-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 12px 3px 12px;
  color: #000;
  color: var(--black);
  font-weight: 600;
}

.devkit-loading-message-wrapper .devkit-message-heading-wrapper .fa-spinner {
  padding: 3px;
  font-size: 1.375rem;
}

.devkit-loading-message-wrapper
  .devkit-message-heading-wrapper
  .message-heading {
  margin-left: 6px;
}

.devkit-loading-message-wrapper .devkit-message-body {
  padding: 3px 12px 12px 46px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  color: var(--black);
}

@media screen and (min-width: 960px) {
  .devkit-loading-message-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

.devkit-success-message-wrapper {
  display: flex;
  width: auto;
  margin: 0 24px 12px 12px;
  font-size: 1rem;
  background: #e7f4e4;
  background: var(--applegreen);
  border-left: 11px solid #22763b;
  border-left: 11px solid var(--green);
}

.devkit-success-message-wrapper
  .devkit-message-heading-wrapper
  .fa-check-circle {
  padding: 24px 3px 3px 20px;
  font-size: 2.25rem;
}

.devkit-success-message-wrapper .devkit-message-body-wrapper {
  width: 100%;
  font-weight: 400;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-success-message-wrapper .devkit-message-body-wrapper .fa-times {
  float: right;
  margin-top: 0;
  padding: 9px 12px 9px 9px;
  color: #333333;
  color: var(--slate);
  font-size: 1rem;
}

.devkit-success-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-heading {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 24px 24px 0 14px;
}

.devkit-success-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-body {
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 0 24px 24px 14px;
}

@media screen and (min-width: 768px) {
  .devkit-success-message-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-success-message-wrapper {
    margin-top: 24px;
    margin-left: 0;
  }
}

.devkit-warning-message-wrapper {
  display: flex;
  width: auto;
  margin: 0 24px 12px 12px;
  font-size: 1rem;
  background: #fff1d2;
  background: var(--varden);
  border-left: 11px solid #fdb81e;
  border-left: 11px solid var(--yellow);
}

.devkit-warning-message-wrapper
  .devkit-message-heading-wrapper
  .fa-exclamation-triangle {
  padding: 24px 3px 3px 20px;
  font-size: 2.125rem;
}

.devkit-warning-message-wrapper .devkit-message-body-wrapper {
  width: 100%;
  font-weight: 400;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-warning-message-wrapper .devkit-message-body-wrapper .fa-times {
  float: right;
  margin-top: 0;
  padding: 9px 12px 9px 9px;
  color: #333333;
  color: var(--slate);
  font-size: 1rem;
}

.devkit-warning-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-heading {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 24px 24px 0 14px;
}

.devkit-warning-message-wrapper
  .devkit-message-body-wrapper
  .devkit-message-body {
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 0 24px 24px 14px;
}

@media screen and (min-width: 768px) {
  .devkit-warning-message-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-warning-message-wrapper {
    margin-left: 0;
  }
}

.devkit-dialog-wrapper {
  font-size: 1rem;
  margin: 0 24px 12px 12px;
}

.devkit-dialog-wrapper button {
  border: 0;
  box-shadow: 0;
}

.devkit-dialog-wrapper .devkit-dialog-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: #0d76bd;
  background: var(--dialog-background);
  color: #ffffff;
  color: var(--white);
  font-weight: 700;
  padding: 0 15px;
}

.devkit-dialog-wrapper .devkit-dialog-body {
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-top: none;
  padding: 15px;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-dialog-wrapper .devkit-dialog-body p {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .devkit-dialog-wrapper {
    width: 400px;
    margin-right: 0;
  }
  .devkit-dialog-wrapper .devkit-dialog-body {
    height: 400px;
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-dialog-wrapper {
    width: 500px;
    margin-left: 0;
  }
}

.devkit-pod-box-wrapper {
  font-size: 1rem;
  margin: 0 24px 12px 12px;
}

.devkit-pod-box-wrapper .devkit-pod-heading {
  width: 280px;
  display: flex;
  align-items: center;
  height: 40px;
  background: #0d76bd;
  background: var(--dialog-background);
  color: #ffffff;
  color: var(--white);
  font-weight: 700;
  padding: 0 15px;
}

.devkit-pod-box-wrapper .devkit-pod-body {
  width: 278px;
  border: solid 1px #cccccc;
  border: solid 1px var(--light-grey);
  border-top: none;
  padding: 15px;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
}

.devkit-pod-box-wrapper .devkit-pod-body p {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .devkit-pod-box-wrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .devkit-pod-box-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

/* MAIN CONTENT LAYOUT */

/* Common */

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/

.devkit-main-wrapper.devkit-no-border {
  border-top: 0;
}

.devkit-main-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto 12px auto 12px auto;
  width: 100%;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-main-wrapper .devkit-main-head {
  grid-column: 1 / span 7;
  grid-row: 1;
  margin-right: 12px;
  margin-left: 12px;
}

.devkit-main-wrapper h1.devkit-sec-head {
  display: none;
}

.devkit-main-wrapper .devkit-main-content-wrapper,
.devkit-main-wrapper .devkit-error-content-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
  margin-left: 12px;
}

.devkit-main-wrapper .devkit-left-nav-link-wrapper {
  grid-column: 1 / span 7;
  grid-row: 5;
}

@media screen and (min-width: 768px) {
  .devkit-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-main-wrapper .devkit-main-head {
    grid-column: 7 / span 16;
    grid-row: 1;
    margin-right: 0;
  }

  .devkit-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }

  .devkit-main-wrapper .devkit-main-content-wrapper,
  .devkit-main-wrapper .devkit-error-content-wrapper {
    grid-column: 7 / span 16;
    grid-row: 2;
  }
}

@media screen and (min-width: 960px) {
  .devkit-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    border-top: 0;
  }

  .devkit-main-wrapper .devkit-main-head {
    grid-column: 7 / span 15;
    grid-row: 1;
    margin-left: 0;
  }

  .devkit-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }

  .devkit-main-wrapper .devkit-main-content-wrapper,
  .devkit-main-wrapper .devkit-error-content-wrapper {
    grid-column: 7 / span 15;
    grid-row: 2;
    margin-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }
  .devkit-main-wrapper .devkit-main-head {
    grid-column: 5 / span 17;
    grid-row: 1;
  }
  .devkit-main-wrapper h1.devkit-sec-head {
    display: block;
    grid-column: 14 / span 2;
    grid-row: 1;
    margin-left: 12px;
  }
  .devkit-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 4;
    grid-row: 2;
  }
  .devkit-main-wrapper .devkit-main-content-wrapper {
    grid-column: 5 / span 17;
    grid-row: 2;
  }
  .devkit-main-wrapper .devkit-error-content-wrapper {
    grid-column: 7 / span 11;
    grid-row: 2;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-main-wrapper .devkit-main-head {
    grid-column: 5 / span 18;
    grid-row: 1;
  }

  .devkit-main-wrapper h1.devkit-sec-head {
    grid-column: 14 / span 2;
    grid-row: 1;
    margin-left: 0;
  }

  .devkit-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 3;
    grid-row: 2;
  }

  .devkit-main-wrapper .devkit-main-content-wrapper {
    grid-column: 5 / span 18;
    grid-row: 2;
  }
  .devkit-main-wrapper .devkit-error-content-wrapper {
    grid-column: 7 / span 11;
    grid-row: 2;
  }
}

/* This is needed to remove grey border on top of
external main content area in desktop mode, mobile and tablet modes.*/

.devkit-left-nav-main-wrapper.devkit-no-border {
  border-top: 0;
}

.devkit-left-nav-main-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-left-nav-main-wrapper .devkit-main-head {
  grid-column: 1 / span 7;
  grid-row: 1;
  margin-left: 12px;
}

.devkit-left-nav-main-wrapper .devkit-content-wrapper {
  grid-column: 1 / span 7;
  grid-row: 2;
  margin-left: 12px;
}

.devkit-left-nav-main-wrapper .devkit-content-wrapper p {
  line-height: 1.5rem;
  margin-top: 0;
}

.devkit-left-nav-main-wrapper .devkit-left-nav-link-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
}

@media screen and (min-width: 768px) {
  .devkit-left-nav-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-left-nav-main-wrapper .devkit-main-head {
    grid-column: 9 / span 14;
    grid-row: 1;
    margin-top: 18px;
    margin-right: 0;
  }

  .devkit-left-nav-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 7;
    grid-row: 2;
  }

  .devkit-left-nav-main-wrapper .devkit-content-wrapper {
    grid-column: 9 / span 14;
    grid-row: 2;
  }
}

@media screen and (min-width: 960px) {
  .devkit-left-nav-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    margin: 24px 0 0 0;
  }

  .devkit-left-nav-main-wrapper .devkit-main-head {
    grid-column: 7 / span 15;
    grid-row: 1;
    margin: 18px 0 0 0;
  }

  .devkit-left-nav-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }

  .devkit-left-nav-main-wrapper .devkit-content-wrapper {
    grid-column: 7 / span 15;
    grid-row: 2;
    margin-right: 12px;
    line-height: 1.875rem;
  }

  .devkit-left-nav-main-wrapper .devkit-content-wrapper p:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-left-nav-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    margin-top: 9px;
  }

  .devkit-left-nav-main-wrapper .devkit-main-head {
    grid-column: 5 / span 17;
    grid-row: 1;
    margin: 24px 0 0 0;
  }

  .devkit-left-nav-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 4;
    grid-row: 2;
    margin-top: 24px;
  }

  .devkit-left-nav-main-wrapper .devkit-content-wrapper {
    grid-column: 5 / span 17;
    grid-row: 2;
    margin: 24px 0 0 0;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-left-nav-main-wrapper .devkit-main-head {
    grid-column: 5 / span 18;
    grid-row: 1;
  }

  .devkit-left-nav-main-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 3;
    grid-row: 2;
  }

  .devkit-left-nav-main-wrapper .devkit-content-wrapper {
    grid-column: 5 / span 18;
    grid-row: 2;
  }
}

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/

.devkit-main-content-box-wrapper.devkit-no-border {
  border-top: 0;
}

.devkit-main-content-box-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: 100%;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-main-content-box-wrapper .devkit-main-head {
  grid-column: 1 / span 7;
  grid-row: 1;
  margin-right: 12px;
  margin-left: 12px;
}

.devkit-main-content-box-wrapper .devkit-success-message-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
}

.devkit-main-content-box-wrapper .devkit-warning-message-wrapper {
  grid-column: 1 / span 7;
  grid-row: 4;
}

.devkit-main-content-box-wrapper .devkit-error-message-wrapper {
  grid-column: 1 / span 7;
  grid-row: 5;
}

.devkit-main-content-box-wrapper .devkit-info-message-wrapper {
  grid-column: 1 / span 7;
  grid-row: 6;
}

.devkit-main-content-box-wrapper .devkit-code-button {
  grid-column: 1 / span 7;
  grid-row: 7;
}

.devkit-main-content-box-wrapper pre {
  grid-column: 1 / span 7;
  grid-row: 8;
}

.devkit-main-content-box-wrapper .devkit-dialog-wrapper {
  grid-column: 1 / span 7;
  grid-row: 9;
}

.devkit-main-content-box-wrapper .devkit-pod-box-wrapper {
  grid-column: 1 / span 7;
  grid-row: 10;
  margin-bottom: 12px;
}

.devkit-main-content-box-wrapper .devkit-left-nav-link-wrapper {
  grid-column: 1 / span 7;
  grid-row: 11;
}

@media screen and (min-width: 768px) {
  .devkit-main-content-box-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-main-content-box-wrapper .devkit-main-head,
  .devkit-main-content-box-wrapper .devkit-dialog-wrapper,
  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper,
  .devkit-main-content-box-wrapper .devkit-success-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-error-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-column: 7 / span 16;
  }

  .devkit-main-content-box-wrapper .devkit-main-head {
    grid-row: 1;
    margin-right: 0;
  }

  .devkit-main-content-box-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-dialog-wrapper {
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper {
    grid-row: 4;
  }

  .devkit-main-content-box-wrapper .devkit-success-message-wrapper {
    grid-row: 6;
  }

  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper {
    grid-row: 7;
  }

  .devkit-main-content-box-wrapper .devkit-error-message-wrapper {
    grid-row: 8;
  }

  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-row: 9;
  }
  .devkit-main-content-box-wrapper .devkit-code-button {
    grid-row: 10;
  }
  .devkit-main-content-box-wrapper pre {
    grid-row: 11;
  }
}

@media screen and (min-width: 960px) {
  .devkit-main-content-box-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    border-top: 0;
  }

  .devkit-main-content-box-wrapper .devkit-main-head,
  .devkit-main-content-box-wrapper .devkit-dialog-wrapper,
  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper,
  .devkit-main-content-box-wrapper .devkit-success-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-error-message-wrapper,
  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-column: 7 / span 16;
  }

  .devkit-main-content-box-wrapper .devkit-main-head {
    grid-row: 1;
    margin-right: 0;
    margin-left: 0;
  }

  .devkit-main-content-box-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-dialog-wrapper {
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper {
    grid-row: 4;
  }

  .devkit-main-content-box-wrapper .devkit-success-message-wrapper {
    grid-row: 6;
  }

  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper {
    grid-row: 7;
  }

  .devkit-main-content-box-wrapper .devkit-error-message-wrapper {
    grid-row: 8;
  }

  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-row: 9;
  }

  .devkit-main-content-box-wrapper .devkit-code-button {
    grid-row: 10;
  }

  .devkit-main-content-box-wrapper pre {
    grid-row: 11;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-main-content-box-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-main-content-box-wrapper .devkit-main-head {
    grid-column: 5 / span 17;
    grid-row: 1;
  }

  .devkit-main-content-box-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 4;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-dialog-wrapper {
    grid-column: 5 / span 9;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper {
    grid-column: 15 / span 8;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-success-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 3;
  }

  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 4;
  }

  .devkit-main-content-box-wrapper .devkit-error-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 5;
  }

  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 6;
  }

  .devkit-main-content-box-wrapper .devkit-code-button {
    grid-column: 5 / span 11;
    grid-row: 7;
  }
  .devkit-main-content-box-wrapper pre {
    grid-column: 5 / span 11;
    grid-row: 8;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-main-content-box-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-main-content-box-wrapper .devkit-main-head {
    grid-column: 5 / span 18;
    grid-row: 1;
  }

  .devkit-main-content-box-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 3;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-dialog-wrapper {
    grid-column: 5 / span 7;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-pod-box-wrapper {
    grid-column: 13 / span 9;
    grid-row: 2;
  }

  .devkit-main-content-box-wrapper .devkit-success-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 3;
  }

  .devkit-main-content-box-wrapper .devkit-warning-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 4;
  }

  .devkit-main-content-box-wrapper .devkit-error-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 5;
  }

  .devkit-main-content-box-wrapper .devkit-info-message-wrapper {
    grid-column: 5 / span 11;
    grid-row: 6;
  }

  .devkit-main-content-box-wrapper .devkit-code-button {
    grid-column: 5 / span 11;
    grid-row: 7;
  }

  .devkit-main-content-box-wrapper pre {
    grid-column: 5 / span 11;
    grid-row: 8;
  }
}

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/

.devkit-forms-wrapper.devkit-no-border {
  border-top: 0;
}

.devkit-forms-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: 100%;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
  margin-left: 12px;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-vertical-tab-area {
  display: flex;
  align-items: flex-start;
  margin-top: 0;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab {
  margin: 0;
  padding-top: 6px;
  padding-left: 0;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li {
  display: block;
  width: 80px;
  border-right-width: 0;
  list-style: none;
  margin: 0 0 1px 0;
  padding: 10px 6px 6px 6px;
  background: #e6e6e6;
  background: var(--tab-background);
  text-decoration: none;
  color: #005690;
  color: var(--link-active);
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
  word-wrap: break-word;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li a:link,
.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li a:hover,
.devkit-forms-wrapper
  .devkit-forms-tab-wrapper
  ul.devkit-forms-tab
  li
  a:visited {
  color: #005690;
  color: var(--link-active);
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li.active {
  background: #ffffff;
  background: var(--white);
  color: #333333;
  color: var(--slate);
  font-weight: 700;
  position: relative;
  right: -1px; /* we need this negative position for active vertical tab border. */
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
  border-right: 0;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li:hover {
  text-decoration: underline;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-form-content {
  display: none;
  min-height: 510px;
  margin-top: 6px;
  padding: 15px;
  font-size: 1rem;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
  border: 1px solid #cccccc;
  border: 1px solid var(--light-grey);
}

.devkit-forms-wrapper
  .devkit-forms-tab-wrapper
  .devkit-form-content
  .devkit-code-button {
  margin-top: 12px;
}

.devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-form-content.active {
  display: block;
}

.devkit-forms-wrapper
  .devkit-forms-tab-wrapper
  .devkit-form-content.active
  pre {
  width: 350px;
}

.devkit-forms-wrapper .devkit-main-head {
  grid-column: 1 / span 7;
  grid-row: 1;
  margin-left: 12px;
  margin-right: 12px;
}

.devkit-forms-wrapper .devkit-left-nav-link-wrapper {
  grid-column: 1 / span 7;
  grid-row: 5;
}

@media screen and (min-width: 768px) {
  .devkit-forms-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper {
    grid-column: 3 / span 19;
    grid-row: 2;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li {
    width: 130px;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-form-content {
    min-height: 420px;
  }
  .devkit-forms-wrapper
    .devkit-forms-tab-wrapper
    .devkit-form-content.active
    pre {
    width: 550px;
  }

  .devkit-forms-wrapper .devkit-main-head {
    grid-column: 3 / span 19;
    grid-row: 1;
  }

  .devkit-forms-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 3 / span 11;
    grid-row: 3;
  }
}

@media screen and (min-width: 960px) {
  .devkit-forms-wrapper {
    border-top: 0;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper {
    grid-column: 7 / span 17;
    grid-row: 2;
    margin-right: 6px;
    margin-left: 0;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-vertical-tab-area {
    display: block;
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab {
    margin: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li {
    width: 90px;
    height: 90px;
    border-bottom-width: 0;
    margin: 0 2px 0 0;
    padding: 8px 14px;
    list-style: none;
    background: #e6e6e6;
    background: var(--tab-background);
    text-decoration: none;
    color: #005690;
    color: var(--link-active);
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
  }

  .devkit-forms-wrapper
    .devkit-forms-tab-wrapper
    ul.devkit-forms-tab
    li.active {
    background: #ffffff;
    background: var(--white);
    color: #333333;
    color: var(--slate);
    font-weight: 700;
    position: relative;
    top: 0;
    right: 0;
    border: 1px solid #cccccc;
    border: 1px solid var(--light-grey);
    border-bottom: 0;
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li:hover {
    text-decoration: underline;
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-form-content {
    display: none;
    margin-top: -2px;
    padding: 15px;
    font-size: 1rem;
    color: #333333;
    color: var(--slate);
    line-height: 1.5rem;
    border: 1px solid #cccccc;
    border: 1px solid var(--light-grey);
  }

  .devkit-forms-wrapper .devkit-forms-tab-wrapper .devkit-form-content.active {
    display: block;
  }
  .devkit-forms-wrapper
    .devkit-forms-tab-wrapper
    .devkit-form-content.active
    pre {
    width: 90%;
  }

  .devkit-forms-wrapper .devkit-main-head {
    grid-column: 7 / span 17;
    grid-row: 1;
    margin-left: 0;
  }

  .devkit-forms-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 5;
    grid-row: 2;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-forms-wrapper .devkit-forms-tab-wrapper {
    grid-column: 5 / span 17;
    grid-row: 2;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li {
    width: 100px;
    height: 56px;
  }

  .devkit-forms-wrapper .devkit-main-head {
    grid-column: 5 / span 17;
    grid-row: 1;
  }

  .devkit-forms-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 4;
    grid-row: 2;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-forms-wrapper .devkit-forms-tab-wrapper {
    grid-column: 5 / span 18;
    grid-row: 2;
  }
  .devkit-forms-wrapper .devkit-forms-tab-wrapper ul.devkit-forms-tab li {
    width: auto;
    height: auto;
  }

  .devkit-forms-wrapper .devkit-main-head {
    grid-column: 5 / span 18;
    grid-row: 1;
  }

  .devkit-forms-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 3;
    grid-row: 2;
  }
}

.devkit-home-content-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: 100%;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-home-content-wrapper .devkit-main-head {
  grid-column: 6 / span 2;
  grid-row: 1;
  margin-right: 12px;
}

.devkit-home-content-wrapper .devkit-home-content,
.devkit-home-content-wrapper .devkit-changelog-content {
  grid-column: 1 / span 7;
  grid-row: 2;
  margin-left: 12px;
}

@media screen and (min-width: 768px) {
  .devkit-home-content-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-home-content-wrapper .devkit-main-head {
    grid-column: 19 / span 5;
    grid-row: 1;
    margin-right: 0;
  }

  .devkit-home-content-wrapper .devkit-home-content,
  .devkit-home-content-wrapper .devkit-changelog-content {
    grid-column: 3 / span 20;
    grid-row: 2;
  }
}

@media screen and (min-width: 960px) {
  .devkit-home-content-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-home-content-wrapper .devkit-main-head {
    grid-column: 20 / span 5;
    grid-row: 1;
    margin-left: 0;
  }

  .devkit-home-content-wrapper .devkit-home-content,
  .devkit-home-content-wrapper .devkit-changelog-content {
    grid-column: 3 / span 20;
    grid-row: 2;
    margin-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-home-content-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-home-content-wrapper .devkit-main-head {
    grid-column: 21 / span 3;
    grid-row: 1;
  }

  .devkit-home-content-wrapper .devkit-home-content,
  .devkit-home-content-wrapper .devkit-changelog-content {
    grid-column: 3 / span 20;
    grid-row: 2;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-home-content-wrapper .devkit-main-head {
    grid-column: 21 / span 3;
    grid-row: 1;
  }

  .devkit-home-content-wrapper .devkit-home-content,
  .devkit-home-content-wrapper .devkit-changelog-content {
    grid-column: 3 / span 20;
    grid-row: 2;
  }
}

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/

.devkit-tabular-content-wrapper.devkit-no-border {
  border-top: 0;
}

.devkit-tabular-content-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: #333333;
  color: var(--slate);
  line-height: 1.5rem;
  margin: 12px auto;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-tabular-content-wrapper .devkit-main-head {
  grid-column: 1 / span 7;
  grid-row: 1;
  margin-left: 12px;
  margin-right: 12px;
}

.devkit-tabular-content-wrapper .devkit-simple-table-wrapper {
  grid-column: 1 / span 7;
  grid-row: 3;
  margin-left: 12px;
}

.devkit-tabular-content-wrapper .devkit-merge-cells-table-wrapper {
  grid-column: 1 / span 7;
  grid-row: 5;
  margin-left: 12px;
}

.devkit-tabular-content-wrapper .devkit-left-nav-link-wrapper {
  grid-column: 1 / span 7;
  grid-row: 7;
}

@media screen and (min-width: 768px) {
  .devkit-tabular-content-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
  }

  .devkit-tabular-content-wrapper .devkit-main-head {
    grid-column: 3 / span 19;
    grid-row: 1;
    margin-right: 0;
  }

  .devkit-tabular-content-wrapper .devkit-simple-table-wrapper {
    grid-column: 3 / span 19;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-merge-cells-table-wrapper {
    grid-column: 3 / span 19;
    grid-row: 5;
  }

  .devkit-tabular-content-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 3 / span 11;
    grid-row: 7;
  }
}

@media screen and (min-width: 960px) {
  .devkit-tabular-content-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    border-top: 0;
  }

  .devkit-tabular-content-wrapper .devkit-main-head {
    grid-column: 7 / span 15;
    grid-row: 1;
    margin-left: 0;
  }

  .devkit-tabular-content-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 7;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-simple-table-wrapper {
    grid-column: 7 / span 15;
    grid-row: 3;
    margin-left: 0;
  }

  .devkit-tabular-content-wrapper .devkit-merge-cells-table-wrapper {
    grid-column: 7 / span 15;
    grid-row: 5;
    margin-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .devkit-tabular-content-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
  }

  .devkit-tabular-content-wrapper .devkit-main-head {
    grid-column: 5 / span 17;
    grid-row: 1;
  }

  .devkit-tabular-content-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 4;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-simple-table-wrapper {
    grid-column: 5 / span 17;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-merge-cells-table-wrapper {
    grid-column: 5 / span 17;
    grid-row: 5;
  }
}

@media screen and (min-width: 1600px) {
  .devkit-tabular-content-wrapper {
    grid-template-columns: 1fr repeat(11, 24px 1fr);
  }

  .devkit-tabular-content-wrapper .devkit-main-head {
    grid-column: 5 / span 18;
    grid-row: 1;
  }

  .devkit-tabular-content-wrapper .devkit-left-nav-link-wrapper {
    grid-column: 1 / span 3;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-simple-table-wrapper {
    grid-column: 5 / span 18;
    grid-row: 3;
  }

  .devkit-tabular-content-wrapper .devkit-merge-cells-table-wrapper {
    grid-column: 5 / span 18;
    grid-row: 5;
  }
}

/* External*/

.devkit-ext-fw-main-wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(3, 16px 1fr);
  grid-template-rows: auto;
  width: auto;
  margin: 12px;
  border-top: solid 2px #e9eaed;
  border-top: solid 2px var(--lighter-grey);
}

.devkit-ext-fw-main-wrapper h1 {
  grid-column: 1 / span 7;
  grid-row: 1;
}

.devkit-ext-fw-main-wrapper .devkit-home-content {
  grid-column: 1 / span 7;
  grid-row: 2;
}

.devkit-ext-fw-main-wrapper .devkit-home-content p {
  line-height: 1.5rem;
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .devkit-ext-fw-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
  }

  .devkit-ext-fw-main-wrapper h1 {
    grid-column: 1 / span 23;
    grid-row: 1;
    margin-top: 18px;
  }

  .devkit-ext-fw-main-wrapper .devkit-home-content {
    grid-column: 1 / span 23;
    grid-row: 2;
  }
}

@media screen and (min-width: 960px) {
  .devkit-ext-fw-main-wrapper {
    display: grid;
    grid-template-columns: 1fr repeat(11, 24px 1fr);
    grid-template-rows: auto;
    width: 960px;
    margin: 0 auto;
    border-top: 0;
  }

  .devkit-ext-fw-main-wrapper h1 {
    grid-column: 1 / span 23;
    grid-row: 1;
    margin-top: 18px;
  }

  .devkit-ext-fw-main-wrapper .devkit-home-content {
    grid-column: 1 / span 23;
    grid-row: 2;
    line-height: 1.875rem;
  }

  .devkit-ext-fw-main-wrapper .devkit-home-content p:first-of-type {
    margin-top: 0;
  }
}
